import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createApp } from 'api/apps';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditAppForm from 'pages/AppSummary/EditAppForm';
import BigBreadcrumb from 'components/BigBreadcrumb';
import { createPage } from 'api/pages';
import { createMenuItem, createMenu } from 'api/menus';
import { useProject } from 'contexts/project';
import { createLayout } from 'api/layouts';
import { createDynamicTableRow, createSection } from 'api/sections';

const AppCreate = () => {
  const [searchParams] = useSearchParams();
  const { setProject } = useProject();
  const solutionId = searchParams.get('solution_id');
  const navigate = useNavigate();

  const createAppToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      solution: solutionId,
      display_name: values.display_name,
      base_url: values.base_url,
      major_version: values.major_version,
      minor_version: values.minor_version,
      type: parseInt(values.type, 10),
    };

    const regex = /^\/[a-zA-Z0-9_]*$/;

    if (!regex.test(values.base_url)) {
      setFieldError('form', 'Invalid base URL. It must start with / and can only contain alphabets, numbers, / and _.');
      return;
    }

    payload.base_url = payload.base_url.toLowerCase();
    let appId;

    return createApp(payload)
      .then(({ data }) => {
        const pagePayload = {
          app: data.app_id,
          path: '/',
          layout: 'f7066f5e-a25e-424e-912f-720eb1fb481a',
          background_image_url: '',
          background_color: '',
          refresh_rate: 0,
        }
        setProject(data)
        appId = data.app_id;
        return pagePayload;
      })
      .then((pagePayload) => {
        return createPage(pagePayload);
      })
      .then(({ data }) => {
        console.log('Created Page', data);
        const menuItemPayload = {
          type: 1,
          new_tab: false,
          path: '/',
          label: values.display_name,
        }
        return createMenuItem(menuItemPayload)
      })
      .then(({ data }) => {
        console.log('Created MenuItem', data);
        const menuPayload = {
          menu_node: data.menu_item_id,
          menu_type: '274e9d1d-1cfe-48c4-af77-60428d78ee63', // by default it is header
          display_name: `${values.display_name} Menu`,
          primary_color: appId,
        }
        return createMenu(menuPayload);
      })
      .then(() => {
        return createDynamicTableRow('1739a198-be91-4e13-8773-fe5ff35da8eb', {})
      })
      .then(({ data }) => {
        console.log('Created Menu', data)
        const layoutPayload = {
          menu: data.menu_id,
          name: `${values.display_name} Layout`,
          header: 'bb5d19ee-159e-4756-b963-8f7e2f085038',
        }
        return createLayout(layoutPayload);
      })
      .then(({ data }) => {
        console.log('Created Layout', data)
      })
      .catch((err) => {
        setFieldError('form', err.message);
      })
      .finally(() => {
        navigate('/webpage/');
      });
  }, [solutionId, setProject, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Apps', to: '/apps' },
            { label: 'Create App' },
          ]}
        />
        <EditAppForm
          onSave={createAppToApi}
        />
      </Container>
    </MKBox>
  );
};

export default AppCreate;

import MKBox from 'components/MaterialKit/MKBox';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useProject } from 'contexts/project';
import { useElement } from 'contexts/element';
import { useSequencer } from 'contexts/sequencer';
import { getDynamicTableRow, createDynamicTableRow, createSection } from 'api/sections';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { getPage, getPages } from 'api/pages';
import { Switch } from '@mui/material';
import Select from 'components/Select';
import Renderer from './renderer';
import PagesMenu from './Pages/pagesMenu';
import ElementsMenu from './Elements/elementsMenu';
import MenusMenu from './Menus/menusMenu';
import ThemesMenu from './Themes/themesMenu';
import SettingsMenu from './Settings/settingsMenu';

const BUILDER_HEADER_BOX_PROPS = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  sx: {
    'height': '8vh',
    '&:hover': { backgroundColor: 'lightgray' },
    'background': 'linear-gradient(0deg, rgba(140,140,140,1) 0%, rgba(99,99,99,1) 100%);',
  },
};

const BUILDER_SIDEMENU_BOX_PROPS = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  sx: {
    borderRight: '1px solid black',
    width: '300px',
    height: '92vh',
    overflow: 'scroll',
  },
};

const BUILDER_SIDEMENU_PAGESELECT_BOX_PROPS = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  sx: {
    borderBottom: '1px solid black',
    height: '8vh',
  },
};

const BUILDER_CONTENT_BOX_PROPS = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  sx: {
    width: 'calc(100% - 300px)',
    height: '92vh',
  },
};

const BUILDER_CONTENT_HEADER_BOX_PROPS = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  sx: {
    height: '8vh',
    width: '100%',
    background: 'white',
    borderBottom: '1px solid black',
  },
};

const BUILDER_CONTENT_BODY_BOX_PROPS = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  sx: {
    height: '84vh',
    width: '100%',
    overflow: 'scroll',
  },
};

const BUILDER_CONTENT_HEADER_TAB_PROPS = {
  display: 'flex',
  alignItems: 'center',
  px: 4,
  sx: {
    'height': '100%',
    '&:hover': { backgroundColor: 'lightgray' },
  },
};

const WebBuilder = () => {
  const { project } = useProject();
  const navigate = useNavigate();
  const [tab, setTab] = useState('elements');
  const [pages, setPages] = useState([]);
  const { element, setElement } = useElement();
  const { sequencer, setSequencer } = useSequencer();
  const { appId } = useParams();
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get('pageId');
  const [page, setPage] = useState(null);
  const { copiedElement, setCopiedElement } = useElement();

  const fetchWebpagesFromApi = useCallback(() => {
    const request_params = {
      'app[eq]': appId,
      '$expand': 'app,layout/header/section_definition/collection_definition/attributes,layout/footer/section_definition/collection_definition/attributes,sections/section_definition/collection_definition/attributes',
    };
    return getPages(request_params)
      .then(({ data }) => {
        return data;
      });
  }, [appId]);

  const fetchCurrentPageFromApi = useCallback(() => {
    if (!pageId || !appId) {
      return Promise.resolve();
    }
    const request_params = {
      'app[eq]': appId,
      '$expand': 'app,layout/header/section_definition/collection_definition/attributes,layout/footer/section_definition/collection_definition/attributes,sections/section_definition/collection_definition/attributes',
    };
    return getPage(pageId, request_params)
      .then(({ data }) => {
        return data;
      });
  }, [appId, pageId]);

  useEffect(() => {
    Promise.all([
      fetchWebpagesFromApi(),
      fetchCurrentPageFromApi(),
    ]).then(([webpages, currentPage]) => {
      setPages(webpages);
      setPage(currentPage);
      if (!currentPage && webpages.length > 0) {
        navigate(`?pageId=${webpages[0].page_id}`);
      } else if (!currentPage) {
        navigate('/webpage');
      }
    });
  }, [fetchCurrentPageFromApi, fetchWebpagesFromApi, navigate, pageId, setPage]);

  const applicationPages = useMemo(() => {
    return pages.map((p) => ({
      value: p.page_id,
      label: p.path,
    }));
  }, [pages]);

  useEffect(() => {
    const seq = window.localStorage.getItem('sequencer');
    if (seq) {
      setSequencer(JSON.parse(seq));
    }
  }, [setSequencer]);

  const handlePageSelection = (id) => {
    const selectedPage = pages.find((p) => p.page_id === id);
    const pageIdentifier = selectedPage?.page_id;
    navigate(`?pageId=${pageIdentifier}`);
    setPage(selectedPage);
  };

  const handleSequencer = () => {
    setSequencer(!sequencer);
  };

  const handlePaste = useCallback(() => {
    if (!copiedElement) {
      return;
    }
    getDynamicTableRow(copiedElement.collection_definition_id, copiedElement.dyn_t)
      .then(({ data }) => {
        return data;
      })
      .then((body) => {
        return createDynamicTableRow(copiedElement.collection_definition_id, body);
      })
      .then(({ data }) => {
        const { id } = data;
        const newSectionBody = {
          section_definition: copiedElement.section_definition_id,
          dyn_t: id,
          page: page?.page_id,
          sequence: 0,
          is_viewport: copiedElement.is_viewport,
        };
        return createSection(newSectionBody);
      })
      .then((response) => {
        if (response.status === 201) {
          window.location.reload();
        }
      });
  }, [copiedElement, page?.page_id]);

  if (!page) {
    return null;
  }

  return (
    <MKBox display="flex" justifyContent="flex-start" flexDirection="column">
      <MKBox p={2} {...BUILDER_HEADER_BOX_PROPS}>
        <Button
          variant="text"
          onClick={() => navigate('/choose')}
          startIcon={<KeyboardArrowLeft />}
          sx={{
            'color': '#fff',
            '&:hover': { color: 'lightgray' },
          }}
        >
          Back
        </Button>
        <MKTypography variant="h6" color="white" fontSize="16px" fontWeight="bold">
          Webpage Builder v.0.1 - &nbsp;
          {project?.display_name || <Button variant="text" onClick={() => navigate('/choose')}> Choose Project </Button>}
        </MKTypography>
      </MKBox>
      <MKBox display="flex" flexDirection="row">
        <MKBox display="flex" flexDirection="column" justifyContent="flex-start">
          <MKBox p={2} {...BUILDER_SIDEMENU_PAGESELECT_BOX_PROPS}>
            <Select
              value={page?.page_id}
              label="Page"
              options={applicationPages}
              onChange={(e) => handlePageSelection(e)}
            />
          </MKBox>
          <MKBox p={2} {...BUILDER_SIDEMENU_BOX_PROPS}>
            {tab === 'pages' && (
              <PagesMenu pageId={page?.page_id} />
            )}
            {tab === 'elements' && (
              <ElementsMenu pageId={page?.page_id} />
            )}
            {tab === 'menu' && (
              <MenusMenu pages={pages} />
            )}
            {tab === 'theme' && (
              <ThemesMenu pages={pages} />
            )}
            {tab === 'setting' && (
              <SettingsMenu pages={pages} />
            )}
          </MKBox>
        </MKBox>

        <MKBox {...BUILDER_CONTENT_BOX_PROPS}>
          <MKBox {...BUILDER_CONTENT_HEADER_BOX_PROPS}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 2, height: '100%' }}>
              <MKBox {...BUILDER_CONTENT_HEADER_TAB_PROPS} onClick={() => { setTab('pages'); }}>
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight={tab === 'pages' ? 'bold' : 'regular'}>
                  Page
                </MKTypography>
              </MKBox>
              <MKBox {...BUILDER_CONTENT_HEADER_TAB_PROPS} backgroundColor="red" onClick={() => { setTab('elements'); }}>
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight={tab === 'elements' ? 'bold' : 'regular'}>
                  Elements
                </MKTypography>
              </MKBox>
              <MKBox {...BUILDER_CONTENT_HEADER_TAB_PROPS} onClick={() => { setTab('menu'); }}>
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight={tab === 'menu' ? 'bold' : 'regular'}>
                  Menu
                </MKTypography>
              </MKBox>
              <MKBox {...BUILDER_CONTENT_HEADER_TAB_PROPS} onClick={() => { setTab('theme'); }}>
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight={tab === 'theme' ? 'bold' : 'regular'}>
                  Theme
                </MKTypography>
              </MKBox>
              <MKBox {...BUILDER_CONTENT_HEADER_TAB_PROPS} onClick={() => { setTab('setting'); }}>
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight={tab === 'setting' ? 'bold' : 'regular'}>
                  Setting
                </MKTypography>
              </MKBox>
              <Button
                type="text"
                onClick={() => {
                  const url = `${window.location.origin}${project.base_url}${page.path}`;
                  window.open(url, '_blank');
                }}
              >
                View Page
              </Button>
            </Stack>
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKBox display="flex" flexDirection="row" alignItems="center">
                <MKTypography variant="h6" color="dark" fontSize="16px" fontWeight="regular">
                  Sequencer
                </MKTypography>
                <Switch checked={sequencer} onChange={handleSequencer} />
              </MKBox>
              {copiedElement && (
                <MKBox display="flex" flexDirection="row" alignItems="center" sx={{ mr: 1 }}>
                  <Button variant="contained" onClick={handlePaste}>
                    <MKTypography variant="body1" fontWeight="bold" fontSize="16px" size="small" color="white">
                      Paste
                    </MKTypography>
                  </Button>
                </MKBox>
              )}
            </MKBox>
          </MKBox>
          {page && (
            <MKBox {...BUILDER_CONTENT_BODY_BOX_PROPS}>
              <Renderer page={page} setSelectedElement={setElement} />
            </MKBox>
          )}
        </MKBox>
      </MKBox>
    </MKBox>
  );
};

export default WebBuilder;

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getSurvey } from 'api/surveys';
import MKBox from 'components/MaterialKit/MKBox';
import Container from '@mui/material/Container';
import BigBreadcrumb from 'components/BigBreadcrumb';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Stack } from '@mui/material';
import SurveyQuestions from './questions';
import SurveyResponses from './responses';
import SurveySettings from './settings';

const SurveySection = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const surveyId = searchParams.get('surveyId');
  const [survey, setSurvey] = useState({});
  const [activeTab, setActiveTab] = useState('questions');

  const fetchSurveyFromApi = useCallback((id) => {
    if (!surveyId) {
      return;
    }
    return getSurvey(id, { $expand: 'survey_questions/survey_question_choices' })
      .then(({ data }) => {
        console.log(data);
        setSurvey(data);
      });
  }, [surveyId]);

  useEffect(() => {
    if (!surveyId) {
      navigate('/reports/surveys');
    }
    fetchSurveyFromApi(surveyId);
  }, [surveyId, navigate, fetchSurveyFromApi]);

  if (!survey) {
    return null;
  }

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Reports', to: '/reports/overview' },
            { label: 'Survey', to: '/reports/survey' },
            { label: survey?.name },
          ]}
        />
        <MKBox mt={2} display="flex" flexDirection="column" justifyContent="flex-start">
          <MKBox p={1} diplay="flex" width="100%" sx={{ border: '1px solid gray', borderRadius: '8px' }}>
            <Stack direction="row" gap={1}>
              <MKBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                onClick={() => setActiveTab('questions')}
                sx={{ background: activeTab === 'questions' ? 'gray' : 'lightgray', borderRadius: '8px', padding: '0.5rem', cursor: 'pointer' }}
              >
                <MKTypography variant="body1" color={activeTab === 'questions' ? 'white' : 'black'} fontSize="16px" fontWeight="bold">
                  Questions
                </MKTypography>
                <MKTypography variant="body1" color={activeTab === 'questions' ? 'white' : 'black'} fontSize="16px" fontWeight="bold">
                  {`(${survey?.survey_questions?.length})`}
                </MKTypography>
              </MKBox>
              <MKBox
                width="100%"
                onClick={() => setActiveTab('response')}
                sx={{ background: activeTab === 'response' ? 'gray' : 'lightgray', borderRadius: '8px', padding: '0.5rem', cursor: 'pointer' }}
              >
                <MKTypography variant="body1" color={activeTab === 'response' ? 'white' : 'black'} fontSize="16px" fontWeight="bold">
                  Responses
                </MKTypography>
              </MKBox>
              <MKBox
                width="100%"
                onClick={() => setActiveTab('settings')}
                sx={{ background: activeTab === 'settings' ? 'gray' : 'lightgray', borderRadius: '8px', padding: '0.5rem', cursor: 'pointer' }}
              >
                <MKTypography variant="body1" color={activeTab === 'settings' ? 'white' : 'black'} fontSize="16px" fontWeight="bold">
                  Settings
                </MKTypography>
              </MKBox>
            </Stack>
          </MKBox>
          {activeTab === 'questions' && (
            <SurveyQuestions survey={survey} onUpdate={() => fetchSurveyFromApi(surveyId)} />
          )}
          {activeTab === 'response' && (
            <SurveyResponses survey={survey} />
          )}
          {activeTab === 'settings' && (
            <SurveySettings survey={survey} />
          )}
        </MKBox>
      </Container>
    </MKBox>
  );
};

SurveySection.propTypes = {};

export default SurveySection;

import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import React, { useMemo, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useProject } from 'contexts/project';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ReportsMenu = ({ open, setOpen }) => {
  const { project } = useProject();
  const navigate = useNavigate();
  const isOpen = useMemo(() => (open === 'reports'), [open]);
  const [selected, setSelected] = useState(null);

  const navigations = [{
    label: 'Overview',
    path: '/reports/overview',
  },
  {
    label: 'Marketing',
    path: '/reports/marketing',
  },
  {
    label: 'Email',
    path: '/reports/email',
  },
  {
    label: 'Survey',
    path: '/reports/surveys',
  }];

  const handleRedirect = (navigation) => {
    setSelected(navigation);
    navigate(`${navigation.path}`);
  };

  return (
    <MKBox>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpen('reports')}
        p={2}
        sx={{ 'cursor': 'pointer',
          '&:hover': { backgroundColor: 'lightgray' },
          'backgroundColor': isOpen ? 'lightgray' : 'white',
        }}
      >
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          Reports
        </MKTypography>
        { isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
      </MKBox>
      { isOpen && (
        <MKBox>
          {navigations.map((navigation) => (
            <MKBox
              pl={4}
              py={1}
              key={navigation.path}
              onClick={() => handleRedirect(navigation)}
              sx={{
                'cursor': 'pointer',
                '&:hover': { backgroundColor: 'lightgray' },
                'backgroundColor': selected?.path === navigation?.path ? 'lightgray' : 'white',
              }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px">
                {navigation?.label}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>
      )}
    </MKBox>

  );
};

ReportsMenu.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.func,
};

ReportsMenu.defaultProps = {
  open: '',
  setOpen: () => {},
};

export default ReportsMenu;

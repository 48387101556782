import React, { useContext } from 'react';

const SectionContext = React.createContext();
export const SectionProvider = SectionContext.Provider;
export const SectionConsumer = SectionContext.Consumer;

export const withSection = (Component) => {
  return (props) => (
    <SectionConsumer>
      {({ section, setSection }) => (
        <Component
          section={section}
          setSection={setSection}
          {...props}
        />
      )}
    </SectionConsumer>
  );
};

export const useSection = () => useContext(SectionContext);

export default SectionContext;

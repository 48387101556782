import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getCollectionDefinitions } from 'api/collection_definitions';
import { getSection, getDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { handleErrorResponse, parseJSON } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useElement } from 'contexts/element';
import PropTypes from 'prop-types';
import ElementEditAttributesForm from './elementEditPropsForm';

const ElementEditProps = ({ sectionId }) => {
  const { setElement } = useElement();
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionConfig, setSectionConfig] = useState({});
  const [collectionDefinitions, setCollectionDefinitions] = useState([]);
  const { setAuth } = useAuth();

  const { section_definition, dyn_t } = currentSection || {};
  const collectionDefinition = section_definition?.collection_definition;
  const collectionDefinitionId = collectionDefinition?.collection_definition_id;
  const collectionDefinitionAttributes = collectionDefinition?.attributes;

  const fetchSectionFromApi = useCallback(() => {
    const sectionParams = {
      $expand: 'section_definition/collection_definition/attributes',
    };
    return getSection(sectionId, sectionParams)
      .then(({ data }) => {
        setCurrentSection(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [sectionId, setAuth]);

  const fetchSectionDataFromApi = useCallback(() => {
    return ((collectionDefinitionId && dyn_t) ? (
      getDynamicTableRow(collectionDefinitionId, dyn_t, {})
    ) : Promise.resolve({ data: null }))
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = parseJSON(jsonStrValue, {});
        setSectionConfig(jsonValue);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinitionId, dyn_t, setAuth]);

  const fetchCollectionDefinitionsFromApi = useCallback(() => {
    const collectionDefinitionParams = {
      $orderBy: 'name',
      $expand: 'attributes',
    };
    return getCollectionDefinitions(collectionDefinitionParams)
      .then(({ data }) => {
        const filteredData = (data || []).filter(({ attributes }) => {
          return attributes?.length > 0;
        });
        setCollectionDefinitions(filteredData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const updateSectionDataToApi = useCallback((values) => {
    const updateBody = {
      json_short_data: JSON.stringify(values),
    };
    if (!collectionDefinitionId || !dyn_t) {
      return Promise.resolve();
    }
    return updateDynamicTableRow(collectionDefinitionId, dyn_t, updateBody)
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = parseJSON(jsonStrValue, {});
        setSectionConfig(jsonValue);
        window.location.reload();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinitionId, dyn_t, setAuth]);

  useEffect(() => {
    fetchSectionFromApi();
  }, [fetchSectionFromApi]);

  useEffect(() => {
    fetchSectionDataFromApi();
  }, [fetchSectionDataFromApi]);

  useEffect(() => {
    fetchCollectionDefinitionsFromApi();
  }, [fetchCollectionDefinitionsFromApi]);

  return (
    <MKBox flex={1} sx={{width: '100%' }}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          {section_definition?.collection_definition?.name || 'Section'}
        </MKTypography>
        <MKBox ml="1rem">
          <CopyToClipboard
            text={sectionId}
            onCopy={(text, result) => console.log({ text, result })}
          >
            <Button
              circular
              color="secondary"
              variant="gradient"
              size="small"
              fontSize={12}
            >
              <ContentCopyIcon sx={{ mr: '0.4rem' }} />
              Copy ID
            </Button>
          </CopyToClipboard>
        </MKBox>
      </MKBox>
      <ElementEditAttributesForm
        attributes={collectionDefinitionAttributes}
        config={sectionConfig}
        collectionDefinitions={collectionDefinitions}
        onSave={updateSectionDataToApi}
        onCancel={() => setElement(null)}
      />
    </MKBox>
  );
};

ElementEditProps.propTypes = {
  sectionId: PropTypes.string.isRequired,
};

export default ElementEditProps;

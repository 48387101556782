import React from 'react'
import PropTypes from 'prop-types'
import MKBox from 'components/MaterialKit/MKBox'
import { renderSectionComponent } from 'utils/sections';

const PagesMenuSequencer = ({ page }) => {
  return (
    <MKBox>
    </MKBox>
  );
};

PagesMenuSequencer.propTypes = {
  page: PropTypes.object,
};

export default PagesMenuSequencer;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import Button from 'components/Button';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { createSurvey, createSurveyQuestion, createSurveyQuestionOption } from 'api/surveys';

const AddQuestionSection = ({ surveyId, onClose }) => {
  const [type, setType] = useState('');
  const [options, setOptions] = useState([]);
  const typeOptions = useMemo(() => [{ label: 'Text', value: 1 }, { label: 'Rank', value: 2 }, { label: 'Multiple Choice', value: 3 }], []);
  const handleAddOption = () => {
    const new_option = {
      label: '',
    };
    setOptions([...options, new_option]);
  };

  const handleTextType = useCallback((values) => {
    const payload = {
      survey: values.survey,
      sequence: values.sequence,
      question_text: values.question_text,
      question_type: values.type,
    };
    console.log('Payload', payload);
    createSurveyQuestion(payload)
      .then(({ data }) => {
        console.log('Question created', data);
        onClose();
      });
  }, [onClose]);

  const handleRankType = useCallback((values) => {
    const payload = {
      survey: values.survey,
      sequence: values.sequence,
      question_text: values.question_text,
      question_type: values.type,
    };
    createSurveyQuestion(payload)
      .then(({ data }) => {
        const splitOptions = [];
        splitOptions.push({
          sequence: 1,
          survey_question: data.survey_question_id,
          label: values.lower_bound,
          answer: '',
        });
        splitOptions.push({
          sequence: values.splits_count,
          survey_question: data.survey_question_id,
          label: values.upper_bound,
          answer: '',
        });
        return Promise.all(splitOptions.map((option) => {
          return createSurveyQuestionOption(option);
        }))
          .then((ids) => {
            onClose();
          });
      });
  }, [onClose]);

  const handleMultipleChoiceType = useCallback((values) => {
    console.log(values);
    const payload = {
      survey: values.survey,
      sequence: values.sequence,
      question_text: values.question_text,
      question_type: values.type,
    };
    console.log(options);
    createSurveyQuestion(payload)
      .then(({ data }) => {
        const splitOptions = options.map((option, index) => {
          return {
            sequence: index + 1,
            survey_question: data.survey_question_id,
            label: values[`option_${index}`],
            answer: '',
          };
        });
        return Promise.all(splitOptions.map((option) => {
          return createSurveyQuestionOption(option);
        }))
          .then((ids) => {
            onClose();
          });
      });
  }, [onClose, options]);

  return (
    <Formik
      initialValues={{
        survey: surveyId,
      }}
      onSubmit={(values) => {
        if (type === 1) {
          handleTextType(values);
        } else if (type === 2) {
          handleRankType(values);
        } else if (type === 3) { handleMultipleChoiceType(values); }
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <MKBox mt={1} p={2} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
          <MKInput
            type="number"
            label="Sequence"
            name="sequence"
            onChange={handleChange('sequence')}
            value={values.sequence}
            fullWidth
          />
          <MKInput
            label="Question Text"
            name="question_text"
            onChange={handleChange('question_text')}
            value={values.question_text}
            fullWidth
            sx={{ mt: 1 }}
          />
          <Select
            label="Question Type"
            name="type"
            value={type}
            onChange={(e) => {
              setType(e);
              setFieldValue('type', e);
            }}
            options={typeOptions}
            fullWidth
            sx={{ mt: 1 }}
          />
          {type === 2 && (
            <>
              <MKInput
                label="Splits Count"
                name="splits_count"
                type="number"
                onChange={handleChange('splits_count')}
                value={values.splits_count || 0}
                fullWidth
                sx={{ mt: 1 }}
              />
              <MKInput
                label="Upper Bound"
                name="upper_bound"
                onChange={handleChange('upper_bound')}
                value={values.upper_bound}
                fullWidth
                sx={{ mt: 1 }}
              />
              <MKInput
                label="Lower Bound"
                name="lower_bound"
                onChange={handleChange('lower_bound')}
                value={values.lower_bound}
                fullWidth
                sx={{ mt: 1 }}
              />
            </>
          )}
          {type === 3 && (
            <MKBox
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="left"
            >
              <MKBox>
                {
                  options.map((option, index) => (
                    <MKInput
                      label={`Option ${index + 1}`}
                      name={`option_${index}`}
                      onChange={handleChange(`option_${index}`)}
                      value={values[`option_${index}`]}
                      fullWidth
                      sx={{ mt: 1 }}
                    />
                  ))
                }
              </MKBox>
              <Stack direction="row" sx={{ mt: 1 }} gap={1}>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => {
                    handleAddOption();
                  }}
                >
                  Add Option
                </Button>
                {options.length !== 0 && (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => {
                    // remove last element of options
                    const newOptions = options.slice(0, -1);
                    setOptions(newOptions);
                  }}
                >
                  Remove Option
                </Button>
                )}
              </Stack>
            </MKBox>
          )}
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleSubmit}
            sx={{ mt: 1 }}
          >
            Submit
          </Button>
        </MKBox>
      )}
    </Formik>
  );
};

AddQuestionSection.propTypes = {
  surveyId: PropTypes.string,
  onClose: PropTypes.func,
};

export default AddQuestionSection;

import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';
import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'
import VideoPlayer from 'components/VideoPlayer';

const VideoPlayerSection = ({ url, source, width, height, ...props }) => {
  const imageProps = useMemo(() => {
    return { ...props };
  }, [props]);

  const videoUrl = useMemo(() => {
    if (imageProps['source.is_from_collection']) {
      return imageProps['source.file_url'];
    }
    return null;
  }, [imageProps]);

  return (
    <VideoPlayer
      url={videoUrl || url}
      width={width}
      height={height}
    />
  )
};

VideoPlayerSection.propTypes = {
  url: PropTypes.string,
  source: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default VideoPlayerSection;

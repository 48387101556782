import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getSurvey, updateSurveyQuestion, deleteSurveyQuestion, updateSurveyQuestionOption } from 'api/surveys';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Checkbox, RadioGroup } from '@mui/material';
import MKInput from 'components/MaterialKit/MKInput';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import AddQuestionSection from './AddQuestion';

const SurveyQuestions = ({ survey, onUpdate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const [sortedQuestions, setSortedQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState(null);

  const handleAddQuestion = () => {
    setNewQuestion({});
  };

  useEffect(() => {
    setSortedQuestions(survey?.survey_questions?.sort((a, b) => a.sequence - b.sequence));
  }, [survey]);

  const handleQuestionUpdateSave = useCallback((question, values) => {
    if (!values.survey_question_id) {
      return;
    }
    if (values.question_type === 1) {
      const payload = {
        question_text: values.question_text,
      };
      return updateSurveyQuestion(values.survey_question_id, payload)
        .then(({ data }) => {
          onUpdate();
        });
    }
    if (values.question_type === 2) {
      const lowest = question.survey_question_choices.find((choice) => choice.sequence === 1);
      const highest = question.survey_question_choices.find((choice) => choice.sequence !== 1);
      const payload = {
        question_text: values.question_text,
      };
      const lowPayload = {
        label: values.lower,
      };
      const highPayload = {
        label: values.upper,
        sequence: values.splits,
      };
      Promise.all([
        updateSurveyQuestionOption(lowest.survey_question_choice_id, lowPayload),
        updateSurveyQuestionOption(highest.survey_question_choice_id, highPayload),
      ])
        .then(() => {
          return updateSurveyQuestion(values.survey_question_id, payload);
        })
        .then(() => {
          onUpdate();
        });
    }
    if (values.question_type === 3) {
      const choices = Object.values(values.choices);
      const choicePayloads = question.survey_question_choices.map((choice, idx) => {
        return {
          id: choice.survey_question_choice_id,
          payload: { label: choices[idx] },
        };
      });
      Promise.all(choicePayloads.map((choicePayload) => {
        return updateSurveyQuestionOption(choicePayload.id, choicePayload.payload);
      }))
        .then((data) => {
          return updateSurveyQuestion(values.survey_question_id, { question_text: values.question_text });
        })
        .then(() => {
          onUpdate();
        });
    }
  }, [onUpdate]);

  const handleQuestionDelete = useCallback((questionId) => {
    return deleteSurveyQuestion(questionId, {})
      .then(() => {
        onUpdate();
      });
  }, [onUpdate]);

  if (!survey) {
    return null;
  }

  if (isEdit) {
    return (
      <MKBox mt={1} display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="warning"
          size="small"
          onClick={() => {
            const currentUrlParams = new URLSearchParams(window.location.search);
            currentUrlParams.set('edit', null);
            window.location.search = currentUrlParams.toString();
          }}
        >
          Stop Editing
        </Button>
        {sortedQuestions?.map((question) => {
          return (
            <Formik
              initialValues={{ ...question,
                lower: question.survey_question_choices?.find((choice) => choice?.sequence === 1)?.label,
                upper: question.survey_question_choices?.find((choice) => choice?.sequence !== 1)?.label,
                splits: (question.survey_question_choices?.find((choice) => choice?.sequence !== 1)?.sequence || 0) - (question.survey_question_choices?.find((choice) => choice?.sequence === 1)?.sequence || 0) + 1,
                choices: question.survey_question_choices?.reduce((acc, choice, idx) => {
                  acc[`choice-${idx}`] = choice.label;
                  return acc;
                }, {}),
              }}
              onSubmit={(values) => {
                return handleQuestionUpdateSave(question, values);
              }}
            >
              {({ values, handleChange, handleSubmit }) => {
                return (
                  <MKBox key={question.question_id} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
                    <MKInput
                      label="Question"
                      fullWidth
                      name="question_text"
                      value={values.question_text}
                      onChange={handleChange('question_text')}
                    />
                    {(question.question_type === 2) && (
                    <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MKInput
                        label="Lower Bound"
                        fullWidth
                        name="lower_bound"
                        value={values.lower}
                        onChange={handleChange('lower')}
                      />
                      <MKInput
                        label="Splits Count"
                        type="number"
                        fullWidth
                        name="splits"
                        value={values.splits || question.survey_question_choices.length}
                        onChange={handleChange('splits')}
                      />
                      <MKInput
                        label="Upper Bound"
                        fullWidth
                        name="upper_bound"
                        value={values.upper}
                        onChange={handleChange('upper')}
                      />

                    </MKBox>
                    )}
                    {question.question_type === 3 && (
                    <MKBox mt={1} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                      {question.survey_question_choices.map((choice, idx) => (
                        <MKInput
                          key={idx}
                          label={`Option ${idx + 1}`}
                          fullWidth
                          name={`choices.choice-${idx}`}
                          value={values.choices[`choice-${idx}`] || choice.label}
                          onChange={handleChange(`choices.choice-${idx}`)}
                          sx={{ mt: 1 }}
                        />
                      ))}
                    </MKBox>
                    )}
                    <Button variant="contained" color="warning" size="small" onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>Save</Button>
                    <Button variant="contained" color="error" size="small" onClick={() => { handleQuestionDelete(question.survey_question_id); }} sx={{ mt: 1 }}>Delete</Button>
                  </MKBox>
                );
              }}
            </Formik>
          );
        })}
        <Button mt={1} variant="contained" color="warning" size="small" onClick={handleAddQuestion} sx={{ mt: 1 }}>Add Question</Button>
        {newQuestion && (<AddQuestionSection surveyId={survey.survey_id} onClose={() => { setNewQuestion(null); onUpdate(); }} />)}
      </MKBox>

    );
  }

  return (
    <MKBox mt={1} display="flex" flexDirection="column">
      <Button
        variant="contained"
        color="warning"
        size="small"
        onClick={() => {
          const currentUrlParams = new URLSearchParams(window.location.search);
          currentUrlParams.set('edit', 'true');
          window.location.search = currentUrlParams.toString();
        }}
      >
        Edit
      </Button>
      {sortedQuestions?.map((question, index) => {
        if (question.question_type === 1) {
          return (
            <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
              <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
              <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <MKInput
                  label="Answer"
                  fullWidth
                />
              </MKBox>
            </MKBox>
          );
        }
        if (question.question_type === 2) {
          const lowerBound = question.survey_question_choices.find((choice) => choice.sequence === 1);
          const upperBound = question.survey_question_choices.find((choice) => choice.sequence !== 1);
          const splits = upperBound.sequence || 0 - lowerBound.sequence || 0 + 1;
          return (
            <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
              <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
              <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <MKTypography variant="subtitle1" fontSize="16px" color="black">{lowerBound?.label}</MKTypography>
                <RadioGroup row>
                  {
                  [...Array(splits)].map((_, i) => (
                    <MKBox key={i} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <Checkbox variant="circular" />
                      <MKTypography variant="subtitle1" fontSize="16px" color="black">{i + 1}</MKTypography>
                    </MKBox>
                  ))
                }
                </RadioGroup>
                <MKTypography variant="subtitle1" fontSize="16px" color="black">{upperBound?.label}</MKTypography>
              </MKBox>
            </MKBox>
          );
        }
        if (question.question_type === 3) {
          return (
            <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
              <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
              <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                {
                  question.survey_question_choices.map((choice, idx) => (
                    <MKBox key={idx} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Checkbox variant="circular" />
                      <MKTypography variant="subtitle1" fontSize="16px" color="black">{choice.label}</MKTypography>
                    </MKBox>
                  ))
                }
              </MKBox>
            </MKBox>
          );
        }
        return null;
      })}
    </MKBox>
  );
};

SurveyQuestions.propTypes = {
  survey: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default SurveyQuestions;

import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const SettingsMenu = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const isOpen = useMemo(() => open === 'settings', [open]);

  const handleOpen = () => {
    setOpen('settings');
    navigate('/settings/');
  };

  return (
    <MKBox>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => handleOpen()}
        p={2}
        sx={{ 'cursor': 'pointer',
          '&:hover': { backgroundColor: 'lightgray' },
          'backgroundColor': isOpen ? 'lightgray' : 'white',
        }}
      >
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          Settings
        </MKTypography>
      </MKBox>
    </MKBox>

  );
};

SettingsMenu.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.func,
};

SettingsMenu.defaultProps = {
  open: '',
  setOpen: () => {},
};

export default SettingsMenu;

import React, { useState } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/auth';
import { AppProvider } from 'contexts/app';
import { ProjectProvider } from 'contexts/project';
import { GlobalDataProvider } from 'contexts/global-data';
import { SectionProvider } from 'contexts/section';
import { ElementProvider } from 'contexts/element';
import AppComponent from 'App';
import { SequencerProvider } from 'contexts/sequencer';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

const App = () => {
  const [app, setApp] = useState(null);
  const [project, setProject] = useState(null);
  const [section, setSection] = useState(null);
  const [element, setElement] = useState(null);
  const [copiedElement, setCopiedElement] = useState(null);
  const [sequencer, setSequencer] = useState(false);

  return (
    <AppProvider value={{ app, setApp }}>
      <AuthProvider>
        <GlobalDataProvider>
          <BrowserRouter basename={app?.base_url || '/'}>
            <ProjectProvider value={{ project, setProject }}>
              <SectionProvider value={{ section, setSection }}>
                <SequencerProvider value={{ sequencer, setSequencer }}>
                  <ElementProvider value={{ element, setElement, copiedElement, setCopiedElement }}>
                    <AppComponent />
                  </ElementProvider>
                </SequencerProvider>
              </SectionProvider>
            </ProjectProvider>
          </BrowserRouter>
        </GlobalDataProvider>
      </AuthProvider>
    </AppProvider>
  );
};

root.render(<App />);

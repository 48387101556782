import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const BigBreadcrumb = ({ items, ...props }) => {
  const navigate = useNavigate();
  return (
    <MKBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" p={1} {...props}>
      <KeyboardBackspaceOutlinedIcon
        onClick={() => { navigate(-1); }}
        sx={{
          'border': '1px solid #ccc',
          'borderRadius': '10px',
          'color': 'text.secondary',
          'cursor': 'pointer',
          'mr': 1,
          '&:hover': { color: 'secondary.main' } }}
      />
      <MKBox display="flex" flexDirection="row">
        { items.map((item, index) => (
          <>
            <MKTypography
              key={item.label}
              fontWeight={index === items.length - 1 ? 'medium' : 'regular'}
              {...(item.to ? {
                to: item.to,
                component: Link,
                sx: {
                  '&:hover, &:focus': {
                    color: ({ palette: { secondary } }) => secondary.focus,
                  },
                },
              } : {})}
            >
              {item.label}
            </MKTypography>
            { index < items.length - 1 && (
              <MKTypography
                key={`${item.label}-separator`}
                color="text.secondary"
                mx={1}
              >
                {'>'}
              </MKTypography>
            )}
          </>
        ))}
      </MKBox>
    </MKBox>
  );
};

BigBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
};

export default BigBreadcrumb;

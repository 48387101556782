import MKBox from 'components/MaterialKit/MKBox';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useProject } from 'contexts/project';
import { getMenuItemDependencies, getMenuItems, getMenus, updateMenu, updateMenuItem, createMenuItem, createMenuItemDependency, deleteMenuItem, deleteMenuItemDependency, updateMenuItemDependency } from 'api/menus';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Formik } from 'formik';
import Select from 'components/Select';
import Button from 'components/Button';
import EditIcon from '@mui/icons-material/Edit';
import MKInput from 'components/MaterialKit/MKInput';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { Stack } from '@mui/material';
import { useSequencer } from 'contexts/sequencer';
import { createPage, getLayouts } from 'api/pages';

const MenusMenu = () => {
  const { project } = useProject();
  const { sequencer, setSequencer } = useSequencer();
  const appId = project?.app_id;
  const [menu, setMenu] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);
  const [menuItemDependencies, setMenuItemDependencies] = React.useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(null);
  const [addNew, setAddNew] = React.useState(false);

  const fetchMenus = useCallback(() => {
    if (!appId) {
      return Promise.resolve();
    }
    return getMenus({ 'primary_color[eq]': appId })
      .then(({ data }) => {
        if (!data.length) {
          return;
        }
        return data[0];
      })
      .then((menuData) => {
        if (!menuData) { return; }
        setMenu(menuData);
        return getMenuItemDependencies({ 'menu_node[eq]': menuData.menu_node });
      })
      .then(({ data }) => {
        setMenuItemDependencies(data.sort((a, b) => a.sequence - b.sequence));
        const ids = data.reduce((acc, item) => {
          acc.push(item.menu_item);
          return acc;
        }, []);
        return ids;
      })
      .then((ids) => {
        return getMenuItems({ 'menu_item_id[in]': ids.join(',') });
      })
      .then(({ data }) => {
        setMenuItems(data);
      })
      .catch((error) => {
        console.error('Error fetching menu', error);
      });
  }, [appId]);

  const updateMenuType = useCallback((values) => {
    return updateMenu(menu?.menu_id, values)
  }, [menu?.menu_id]);

  const onAddMenuItem = useCallback(() => {
    setAddNew(true);
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [appId, fetchMenus, selectedMenuItem]);

  const handleDeleteMenuItem = useCallback((id) => {
    return deleteMenuItem(id)
      .then(({ data }) => {
        setSelectedMenuItem(null);
        fetchMenus();
      });
  }, [fetchMenus]);

  const submitMenuItemUpdate = useCallback((values) => {
    return updateMenuItem(selectedMenuItem.menu_item_id, values)
      .then(({ data }) => {
        setSelectedMenuItem(null);
      });
  }, [selectedMenuItem]);

  const submitMenuItemCreate = useCallback((values) => {
    const pay = { ...values, new_tab: false, type: 2 };
    return createMenuItem(pay)
      .then(({ data }) => {
        return data.menu_item_id;
      })
      .then((menuItemId) => {
        return createMenuItemDependency({ menu_node: menu.menu_node, menu_item: menuItemId });
      })
      .then(({ data }) => {
        return getLayouts({ 'menu[eq]': menu.menu_id });
      })
      .then(({ data }) => {
        console.log('layouts', data);
        if (!data.length) {
          return;
        }
        return data[0];
      })
      .then((layout) => {
        const payload = {
          layout: layout?.layout_id || 'f7066f5e-a25e-424e-912f-720eb1fb481a',
          app: appId,
          path: values.path,
          background_color: '',
          backround_image_url: '',
          refresh_rate: 0,
        };
        return createPage(payload);
      })
      .then(({data}) => {
        console.log('page', data);
      })
      .finally(() => {
        setAddNew(false);
        fetchMenus();
        window.location.reload();
      });
  }, [appId, fetchMenus, menu?.menu_id, menu?.menu_node]);

  const handleSequenceSubmit = useCallback((values) => {
    const promises = Object.keys(values).map((key) => {
      return updateMenuItemDependency(key, { sequence: values[key] });
    });
    return Promise.all(promises)
      .then(() => {
        fetchMenus();
        setSequencer(false);
      });
  }, [fetchMenus, setSequencer]);

  const sortedMenuItems = useMemo(() => {
    // sort menu items by sequence based on the menuItemDependencies
    return menuItemDependencies.map((dependency) => {
      const ret = { sequence: dependency.sequence, depkey: dependency.menu_item_dependency_id, item: menuItems.find((item) => item.menu_item_id === dependency.menu_item) };
      return ret;
    });
  }, [menuItems, menuItemDependencies]);

  const sequenceFormikInitialValues = useMemo(() => {
    return sortedMenuItems.reduce((acc, item) => {
      acc[item?.depkey] = item.sequence;
      return acc;
    }, {});
  }, [sortedMenuItems]);

  if (!menu) {
    return null;
  }

  const menuTypesOptions = [
    { value: '274e9d1d-1cfe-48c4-af77-60428d78ee63', label: 'Header' },
    { value: '4a154fb4-87f8-4bf1-a6bd-4f1eaec28dc1', label: 'Drawer' },
    { value: 'f9d9230b-9944-47d5-b0ae-4dcadfdce12c', label: 'Sidebar' },
  ]

  const menuItemNotSelected = () => {
    return (
      <>
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          {menu.display_name}
        </MKTypography>
        <Formik initialValues={{ menu_type: menu.menu_type }} onSubmit={updateMenuType}>
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <MKBox
                display="flex"
                flexDirection="column"
                justifyContent="start"
                mt={1}
              >
                <Select
                  label="Menu Type"
                  options={menuTypesOptions}
                  value={values.menu_type}
                  onChange={(option) => setFieldValue('menu_type', option)}
                />
                <Button type="submit" onClick={handleSubmit} sx={{ mt: 1 }}>Save</Button>
              </MKBox>
            );
          }}
        </Formik>
        <MKBox mt={2} display="flex" flexDirection="column">
          <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
            Menu Items
          </MKTypography>
          {sequenceFormikInitialValues && sequencer && (
            <Formik initialValues={{ ...sequenceFormikInitialValues }} onSubmit={(values) => { handleSequenceSubmit(values) }}>
              {({ values, setFieldValue, handleSubmit }) => {
                return (
                  <MKBox>
                    {
                      sortedMenuItems.map(({ depkey, item }) => {
                        return (
                          <MKBox key={item?.menu_item_id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 1, px: 2, border: '1px solid gray', borderRadius: '8px' }} mb={1}>
                            <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold" sx={{ mr: 2 }}>
                              {item?.label?.length > 15 ? `${item?.label.slice(0, 15)}...` : item?.label}
                            </MKTypography>
                            <MKInput type="number" name={depkey} value={values[depkey]} onChange={(e) => setFieldValue(depkey, e.target.value)} sx={{ maxWidth: '75px', minWidth: '50px' }} />
                            {item?.disabled ? <HideSourceIcon /> : ''}
                          </MKBox>
                        );
                      })
                    }
                    <Button type="submit" fullWidth onClick={handleSubmit}>Save Sequence</Button>
                  </MKBox>
                )
              }}
            </Formik>
          )}
          {
            !sequencer && sortedMenuItems.map(({ sequence, item }) => {
              return (
                <MKBox key={item?.menu_item_id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 1, px: 2, border: '1px solid gray', borderRadius: '8px' }} mb={1}>
                  <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
                    {item?.label?.length > 15 ? `${item?.label.slice(0, 15)}...` : item?.label}
                  </MKTypography>
                  {item?.disabled ? <HideSourceIcon /> : ''}
                  <Button variant="text" size="large" circular onClick={() => { setSelectedMenuItem(item); setAddNew(false); }} sx={{ maxWidth: '1em' }}><EditIcon /></Button>
                </MKBox>
              );
            })
          }
          <Button onClick={onAddMenuItem} circular sx={{ mt: 1 }}>+</Button>
        </MKBox>
      </>
    )
  }

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="start"
      width="100%"
    >
      {!selectedMenuItem && menuItemNotSelected()}
      {addNew && (
        <MKBox display="flex" flexDirection="column" mt={2}>
          <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
            Create new menu item
          </MKTypography>
          <Formik initialValues={{ label: '', path: '/', disabled: false }} onSubmit={submitMenuItemCreate}>
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <MKBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  mt={1}
                >
                  <MKInput label="Label" value={values.label} onChange={(e) => setFieldValue('label', e.target.value)} />
                  <MKInput label="Path" value={values.path} onChange={(e) => setFieldValue('path', e.target.value)} sx={{ my: 1 }} />
                  <Select label="Disabled" value={values.disabled} options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]} onChange={(v) => setFieldValue('disabled', v)} />
                  <Stack direction="row" spacing={2} mt={2}>
                    <Button fullWidth onClick={() => { setAddNew(false) }} sx={{ mt: 1 }}>Close</Button>
                    <Button fullWidth type="submit" onClick={handleSubmit} sx={{ mt: 1 }}>Submit</Button>
                  </Stack>
                </MKBox>
              );
            }}
          </Formik>
        </MKBox>
      )}
      {selectedMenuItem && (
        <MKBox display="flex" flexDirection="column">
          <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
            {selectedMenuItem.label}
          </MKTypography>
          <Formik initialValues={{ label: selectedMenuItem.label, path: selectedMenuItem.path, disabled: selectedMenuItem.disabled }} onSubmit={submitMenuItemUpdate}>
            {({ values, setFieldValue, handleChange, handleSubmit }) => {
              return (
                <MKBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  mt={1}
                >
                  <MKInput label="Label" value={values.label} onChange={(e) => setFieldValue('label', e.target.value)} />
                  <MKInput label="Path" value={values.path} onChange={(e) => setFieldValue('path', e.target.value)} sx={{ my: 1 }} />
                  <Select label="Disabled" value={values.disabled} options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]} onChange={(v) => setFieldValue('disabled', v)} />
                  <Stack direction="row" spacing={2} mt={2}>
                    <Button fullWidth onClick={() => { setSelectedMenuItem(null); }} sx={{ mb: 1 }}>Back</Button>
                    <Button fullWidth onClick={() => handleDeleteMenuItem(selectedMenuItem.menu_item_id)} sx={{ mt: 1 }}>Delete</Button>
                    <Button fullWidth type="submit" onClick={handleSubmit} sx={{ mt: 1 }}>Save</Button>
                  </Stack>
                </MKBox>
              );
            }}
          </Formik>
        </MKBox>
      )}
    </MKBox>
  );
};

export default MenusMenu;

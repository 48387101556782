import api from 'api';

export const getLayouts = (params) => {
    return api.get('/layouts', params, 'bearer');
};
export const updateLayout = (id, body) => {
    return api.put(`/layouts/${id}`, body, 'bearer');
};

export const createLayout = (body) => {
    return api.post('/layouts', body, 'bearer');
};

export const deleteLayout = (id) => {
    return api.delete(`/layouts/${id}`, 'bearer');
};

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useAuth } from 'contexts/auth';
import { useSearchParams } from 'react-router-dom';
import { getSurvey, createSurveyAnswerSubmission, createSurveyAnswer } from 'api/surveys';
import MKInput from 'components/MaterialKit/MKInput';
import Button from 'components/Button';
import { Formik } from 'formik';
import { Checkbox, Radio } from '@mui/material';

const SurveyFormSubmitSection = () => {
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('surveyId');
  const [survey, setSurvey] = useState(null);

  const fetchSurveyFromApi = useCallback(() => {
    if (!surveyId) {
      return Promise.resolve();
    }
    return getSurvey(surveyId, {
      $expand: 'survey_questions/survey_question_choices',
    })
      .then(({ data }) => { setSurvey(data); });
  }, [surveyId]);

  useEffect(() => {
    fetchSurveyFromApi();
  }, [fetchSurveyFromApi]);

  const questions = useMemo(() => survey?.survey_questions || [], [survey]);
  const sortedQuestions = useMemo(() => questions.sort((a, b) => a.sequence - b.sequence), [questions]);
  console.log('questions', sortedQuestions);

  const { auth, setAuth } = useAuth();
  const user = auth?.user;

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        const payload = {
          user: user?.username,
        };
        createSurveyAnswerSubmission(payload)
          .then(({ data }) => {
            return data.survey_question_answer_submission_id;
          })
          .then((submissionId) => {
            return Promise.all([sortedQuestions.forEach((question) => {
              const questionPayload = {
                survey_question: question.survey_question_id,
                survey_question_answer_submission: submissionId,
              };
              if (question.question_type === 3) {
                questionPayload.survey_question_choice = values[`question_${question.survey_question_id}`];
              } else {
                questionPayload.survey_question_answer_text = values[`question_${question.survey_question_id}`];
              }
              return createSurveyAnswer(questionPayload);
            })]);
          })
          .then((data) => {
            alert('Survey submitted', data);
          });
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, touched, isSubmitting, dirty }) => {
        return (
          <MKBox mt={1} display="flex" flexDirection="column">
            <MKTypography variant="h4" color="black">{`Survey: ${survey?.name}`}</MKTypography>
            <MKTypography variant="h6" color="black">{`Description: ${survey?.description}`}</MKTypography>
            <MKTypography variant="h6" color="black">{`User: ${user?.username}`}</MKTypography>
            {sortedQuestions?.map((question, index) => {
              if (question.question_type === 1) {
                return (
                  <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
                    <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
                    <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MKInput
                        label="Answer"
                        name={`question_${question.survey_question_id}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </MKBox>
                  </MKBox>
                );
              }
              if (question.question_type === 2) {
                const lowerBound = question.survey_question_choices.find((choice) => choice.sequence === 1);
                const upperBound = question.survey_question_choices.find((choice) => choice.sequence !== 1);
                const splits = upperBound.sequence || 0 - lowerBound.sequence || 0 + 1;
                return (
                  <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
                    <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
                    <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MKTypography variant="subtitle1" fontSize="16px" color="black">{lowerBound?.label}</MKTypography>
                      <MKBox display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
                        {
                      [...Array(splits)].map((_, i) => (
                        <MKBox key={i} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                          <Checkbox
                            checked={values[`question_${question.survey_question_id}`] === i + 1}
                            onChange={(e) => {
                              setFieldValue(`question_${question.survey_question_id}`, i + 1);
                            }}
                          />
                          <MKTypography variant="subtitle1" fontSize="16px" color="black">{i + 1}</MKTypography>
                        </MKBox>
                      ))
                    }
                      </MKBox>
                      <MKTypography variant="subtitle1" fontSize="16px" color="black">{upperBound?.label}</MKTypography>
                    </MKBox>
                  </MKBox>
                );
              }
              if (question.question_type === 3) {
                return (
                  <MKBox key={index} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
                    <MKTypography variant="h6" color="black">{`${question?.sequence || '0'}. ${question.question_text}`}</MKTypography>
                    <MKBox mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      {
                      question.survey_question_choices.map((choice, idx) => (
                        <MKBox key={idx} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                          <Checkbox
                            checked={values[`question_${question.survey_question_id}`] === choice.survey_question_choice_id}
                            onChange={(e) => {
                              setFieldValue(`question_${question.survey_question_id}`, choice.survey_question_choice_id);
                            }}
                          />
                          <MKTypography variant="subtitle1" fontSize="16px" color="black">{choice.label}</MKTypography>
                        </MKBox>
                      ))
                    }
                    </MKBox>
                  </MKBox>
                );
              }
              return null;
            })}
            <Button size="small" onClick={handleSubmit} sx={{mt: 1}}>Submit</Button>
          </MKBox>
        );
      }}
    </Formik>
  );
};

export default SurveyFormSubmitSection;

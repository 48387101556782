import React, { useContext } from 'react';

const ElementContext = React.createContext();
export const ElementProvider = ElementContext.Provider;
export const ElementConsumer = ElementContext.Consumer;

export const withElement = (Component) => {
  return (props) => (
    <ElementConsumer>
      {({ element, setElement, copied, setCopied }) => (
        <Component
          element={element}
          setElement={setElement}
          copied={copied}
          setCopied={setCopied}
          {...props}
        />
      )}
    </ElementConsumer>
  );
};

export const useElement = () => useContext(ElementContext);

export default ElementContext;

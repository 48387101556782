import React, { useContext } from 'react';

const ProjectContext = React.createContext();
export const ProjectProvider = ProjectContext.Provider;
export const ProjectConsumer = ProjectContext.Consumer;

export const withProject = (Component) => {
  return (props) => (
    <ProjectConsumer>
      {({ project, setProject }) => (
        <Component
          project={project}
          setProject={setProject}
          {...props}
        />
      )}
    </ProjectConsumer>
  );
};

export const useProject = () => useContext(ProjectContext);

export default ProjectContext;

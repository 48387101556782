/* eslint-disable react/prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import Button from 'components/MaterialKit/MKButton';
import { Stack } from '@mui/material';
import { getSections, deleteSection, deleteDynamicTableRow } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const SectionList = ({ webpage }) => {
  const [sections, setSections] = useState([]);
  const pageId = webpage?.page_id;
  const appId = webpage?.app;
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const sectionParams = {
      page: pageId,
      $orderBy: 'sequence',
      $expand: 'section_definition/collection_definition',
    };
    return getSections(sectionParams)
      .then(({ data }) => {
        setSections(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [pageId, setAuth]);

  const deleteSectionFromApi = useCallback((section) => {
    const { section_id, section_definition, dyn_t } = section;
    const collectionDefinitionId = section_definition?.collection_definition?.collection_definition_id;
    return deleteSection(section_id)
      .then(() => deleteDynamicTableRow(collectionDefinitionId, dyn_t))
      .then(() => fetchSectionsFromApi())
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionsFromApi, setAuth]);

  const onClickAdd = useCallback(() => {
    navigate(`/app/${appId}/page/${pageId}/section`);
  }, [appId, pageId, navigate]);

  const onClickEdit = useCallback((sectionId) => {
    navigate(`/app/${appId}/page/${pageId}/section/${sectionId}`);
  }, [appId, pageId, navigate]);

  const onClickDelete = useCallback((sectionId) => {
    const section = sections.find(({ section_id }) => section_id === sectionId);
    return deleteSectionFromApi(section);
  }, [sections, deleteSectionFromApi]);

  useEffect(() => {
    fetchSectionsFromApi();
  }, [fetchSectionsFromApi]);

  const sectionTableColumns = useMemo(() => [
    { field: 'sequence', width: '5%' },
    {
      field: 'section_definition.collection_definition.name',
      label: 'Name',
      width: '65%',
    },
    {
      field: 'action',
      label: '',
      align: 'right',
      width: '30%',
    },
  ], []);

  const rowData = useMemo(() => sections.map((section) => ({
    ...section,
    action: (
      <Stack direction="row" justifyContent="end" gap={1}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => onClickEdit(section.section_id)}
        >
          Edit
        </Button>

        <Button
          variant="contained"
          color="warning"
          size="small"
          onClick={() => navigate(`/section-attributes/${section.section_id}`)}
        >
          View
        </Button>
      </Stack>
    ),
  })), [sections, onClickEdit, navigate]);

  return (
    <MKBox mt={1}>
      <DataTable
        data={rowData}
        columns={sectionTableColumns}
        idField="section_id"
        onPressAdd={onClickAdd}
        onPressDelete={onClickDelete}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

export default SectionList;

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import BigBreadcrumb from 'components/BigBreadcrumb';
import WebpageDashboardSection from 'sections/WebpagesDashboard';
import { useProject } from 'contexts/project';
import SectionList from './sectionList';

const EditPageSection = ({ ...props }) => {
  const location = useLocation();
  const { project } = useProject();
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (location.state?.page) {
      setPage(location.state.page);
    }
  }, [location.state]);

  const breadcrumbItems = useMemo(() => [
    { label: 'Webpage', to: '/webpage/' },
    { label: page?.path, to: '/webpage/' },
  ], [page]);

  const pageInfo = useMemo(() => {
    return location.state?.page;
  }, [location.state]);

  if (!pageInfo) {
    return (
      <MKBox {...props}>
        <BigBreadcrumb items={[{ label: 'Webpage', to: '/webpage/' }, { label: 'Dashboard', to: '/webpage/' }]} />
        <WebpageDashboardSection projectId={project?.app_id || null} />
      </MKBox>
    );
  }

  return (
    <MKBox {...props}>
      <BigBreadcrumb items={breadcrumbItems} />
      {/* <EditPageMain webpage={page} /> */}
      <SectionList webpage={page} />
    </MKBox>
  );
};

export default EditPageSection;

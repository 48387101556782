import React, { useContext } from 'react';

const SequencerContext = React.createContext();
export const SequencerProvider = SequencerContext.Provider;
export const SequencerConsumer = SequencerContext.Consumer;

export const withSequencer = (Component) => {
  return (props) => (
    <SequencerConsumer>
      {({ sequencer, setSequencer }) => (
        <Component
          sequencer={sequencer}
          setSequencer={setSequencer}
          {...props}
        />
      )}
    </SequencerConsumer>
  );
};

export const useSequencer = () => useContext(SequencerContext);

export default SequencerContext;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { handleErrorResponse } from 'utils/general';
import { getMenus } from 'api/menus';
import { useAuth } from 'contexts/auth';
import PropTypes from 'prop-types';

const MenuSection = ({ app, ...props }) => {
  const [menus, setMenus] = useState([]);
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const appId = useMemo(() => app?.app_id, [app]);

  const onPressEdit = useCallback((menuId) => {
    navigate(`/app/${appId}/menu/${menuId}`);
  }, [appId, navigate]);

  const fetchMenusFromApi = useCallback(() => {
    const menuParams = {
      $expand: 'menu_node',
    };
    return getMenus(menuParams)
      .then(({ data }) => {
        setMenus(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    fetchMenusFromApi();
  }, [fetchMenusFromApi]);

  const menuTableColumns = useMemo(() => [
    { field: 'display_name' },
    {
      field: 'menu_node.label',
      label: 'Starting Menu Node',
    },
  ], []);

  return (
    <MKBox {...props}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Menus</MKTypography>
      </MKBox>
      <DataTable
        data={menus}
        columns={menuTableColumns}
        idField="menu_id"
        onPressEdit={onPressEdit}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

MenuSection.defaultProps = {
  app: {},
};

MenuSection.propTypes = {
  app: PropTypes.object,
};

export default MenuSection;

import get from 'lodash/get';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'contexts/auth';
import { fetchSectionProps, renderSectionComponentInBuilder } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { deleteSection, getDynamicTableRow, updateSection } from 'api/sections';
import { useSequencer } from 'contexts/sequencer';
import { Formik } from 'formik';
import MKInput from 'components/MaterialKit/MKInput';
import { Button, Grid } from '@mui/material';
import { useElement } from 'contexts/element';

const renderNotFoundPage = (isLoaded) => (
  <MKBox display="flex" justifyContent="center" alignItems="center" height="100vh">
    {isLoaded ? (
      <MKTypography variant="h1" color="dark" textGradient fontSize="40vw">404</MKTypography>
    ) : (
      <CircularProgress size="4rem" color="secondary" thickness={6} />
    )}
  </MKBox>
);

const Renderer = ({ page, setSelectedElement }) => {
  const { auth, setAuth } = useAuth();
  const { copiedElement, setCopiedElement } = useElement();
  const [sectionsProps, setSectionsProps] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);
  const layout = useMemo(() => get(page, 'layout') || {}, [page]);
  const sections = useMemo(() => get(page, 'sections') || [], [page]);
  const { sequencer, setSequencer } = useSequencer();
  const [sectionSequences, setSectionSequences] = useState({});

  useEffect(() => {
    const sequences = sections.map((section) => {
      return {
        id: section.section_id,
        sequence: section.sequence,
      };
    });
    setSectionSequences(sequences);
  }, [sections]);

  const onPressEditSection = useCallback((sectionId) => {
    setSelectedElement(sectionId);
  }, [setSelectedElement]);

  const onPressDeleteSection = useCallback((sectionId) => {
    return deleteSection(sectionId)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const onPressCopySection = useCallback((sectionId) => {
    const sectionToCopy = sections.find((section) => section.section_id === sectionId);
    const copiedSectionCollectionDefinitionId = sectionToCopy.section_definition.collection_definition.collection_definition_id;
    const copiedSectionDynamicTableId = sectionToCopy.dyn_t;
    const body = {
      collection_definition_id: copiedSectionCollectionDefinitionId,
      dyn_t: copiedSectionDynamicTableId,
      section_definition_id: sectionToCopy.section_definition.section_definition_id,
      sequence: sectionToCopy.sequence,
      is_viewport: sectionToCopy.is_viewport,
    };
    console.log('copiedSectionData', body);
    setCopiedElement(body);
  }, [sections, setCopiedElement]);

  const fetchSectionDataFromApi = useCallback((section) => {
    const { section_id } = section;
    return fetchSectionProps(section, !auth.user)
      .then((sectionProps) => {
        setSectionsProps((oriSectionsProps) => {
          const updatedSectionsProps = { ...oriSectionsProps };
          updatedSectionsProps[section_id] = sectionProps;
          return updatedSectionsProps;
        });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth.user, setAuth]);

  useEffect(() => {
    const totalSections = [
      ...(layout?.header ? [layout.header] : []),
      ...(layout?.footer ? [layout.footer] : []),
      ...sections,
    ];
    setSectionsProps((oriSectionsProps) => {
      return (Object.keys(oriSectionsProps)).reduce((prevSectionProps, sectionId) => {
        const updatedSectionsProps = { ...prevSectionProps };
        const existingSection = totalSections.find((section) => section.section_id === sectionId);
        if (existingSection) {
          updatedSectionsProps[sectionId] = oriSectionsProps[sectionId];
        }
        return updatedSectionsProps;
      }, {});
    });
    (totalSections || []).forEach((section) => {
      fetchSectionDataFromApi(section);
    });
  }, [layout, sections, fetchSectionDataFromApi]);

  const handleSequencerFormSubmit = useCallback((values) => {
    const updateBodies = Object.keys(values).map((sectionId) => {
      return {
        section_id: sectionId,
        body: {
          sequence: values[sectionId],
        },
      };
    }, []);
    Promise.all([
      ...updateBodies.map((updateBody) => {
        return updateSection(updateBody.section_id, updateBody.body);
      }),
    ])
      .then((data) => {
        window.location.reload();
      });
  }, []);

  return page ? (
    <MKBox display="flex" flexDirection="column" sx={{ height: '100%', width: '100%' }}>
      <MKBox display="flex" flexDirection="column" flex={1} sx={{ minWidth: 0 }}>
        {renderSectionComponentInBuilder(layout?.header, sectionsProps[layout?.header?.section_id], true, onPressEditSection, onPressDeleteSection, onPressCopySection)}
        {sequencer && (
          <MKBox>
            <Formik
              initialValues={
                sections.reduce((acc, section) => {
                  acc[section.section_id] = section.sequence;
                  return acc;
                }, {})
              }
            >
              {({ handleChange, values }) => {
                return (
                  <MKBox component="form" role="form" onSubmit={handleSequencerFormSubmit}>
                    <Grid container xs={12} justifyContent="start" alignItems="center">
                      <Grid container item xs={12} justifyContent="center">
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          fullWidth
                          onClick={() => { handleSequencerFormSubmit(values); }}
                          sx={{ m: 1 }}
                        >
                          <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                            Save Section sequences
                          </MKTypography>
                        </Button>
                      </Grid>
                    </Grid>
                    {sections.sort((s1, s2) => s1.sequence - s2.sequence).map((section, idx) => {
                      return (
                        <Grid container xs={12} justifyContent="start" alignItems="center">
                          <Grid container item xs={1} justifyContent="center">
                            <MKInput
                              type="number"
                              label="#"
                              value={values[section.section_id]}
                              onChange={handleChange(section.section_id)}
                              sx={{ maxWidth: '3em' }}
                            />
                          </Grid>
                          <Grid item xs={11}>
                            <Fragment key={`${section.section_id}-${idx}`}>
                              {renderSectionComponentInBuilder(section, sectionsProps[section?.section_id], true, onPressEditSection, onPressDeleteSection, onPressCopySection)}
                            </Fragment>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </MKBox>
                );
              }}
            </Formik>
          </MKBox>
        )}
        {!sequencer && (
          <MKBox>
            {sections.sort((s1, s2) => s1.sequence - s2.sequence).map((section, idx) => {
              return (
                <Fragment key={`${section.section_id}-${idx}`}>
                  {renderSectionComponentInBuilder(section, sectionsProps[section?.section_id], true, onPressEditSection, onPressDeleteSection, onPressCopySection)}
                </Fragment>
              );
            })}
          </MKBox>
        )}
        {renderSectionComponentInBuilder(layout?.footer, sectionsProps[layout?.footer?.section_id], true, onPressEditSection, onPressDeleteSection, onPressCopySection)}
      </MKBox>
    </MKBox>
  ) : renderNotFoundPage(pageLoaded);
};

Renderer.defaultProps = {
  page: null,
  setSelectedElement: () => { },
};

Renderer.propTypes = {
  page: PropTypes.object,
  setSelectedElement: PropTypes.func,
};

export default Renderer;

import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { Stack, Tooltip } from '@mui/material';

const EditableWrapper = ({ editable, sequence, onPressEdit, onPressDelete, onPressCopy, children, sx, ...props }) => {
  return editable ? (
    <MKBox position="relative" sx={{ border: '2px dotted', m: 0.2, ...sx }} {...props}>
      {children}
      <MKBox
        position="absolute"
        right={4}
        top={4}
        sx={{ 'zIndex': 1500, 'opacity': 0.7, '&:hover': { zIndex: 1600, opacity: 1 } }}
      >
        <Stack gap={1} direction="row">
          {onPressEdit && (
          <Button
            circular
            iconOnly
            color="dark"
            variant="gradient"
            size="small"
            onClick={onPressEdit}
            title="Edit"
          >
            <Tooltip title="Edit" disableFocusListener>
              <EditOutlinedIcon />
            </Tooltip>
          </Button>
          )}
          {onPressDelete && (
          <Button
            circular
            iconOnly
            color="dark"
            variant="gradient"
            size="small"
            onClick={onPressDelete}
            title="Delete"
          >
            <Tooltip title="Delete" disableFocusListener>
              <DeleteOutlinedIcon />
            </Tooltip>
          </Button>
          )}
          {onPressCopy && (
          <Button
            circular
            iconOnly
            color="dark"
            variant="gradient"
            size="small"
            onClick={onPressCopy}
            title="Copy"
          >
            <Tooltip title="Copy" disableFocusListener>
              <ContentCopyOutlinedIcon />
            </Tooltip>
          </Button>
          )}
        </Stack>

      </MKBox>
    </MKBox>
  ) : children;
};

EditableWrapper.propTypes = {
  editable: PropTypes.bool,
  sequence: PropTypes.number,
  onPressEdit: PropTypes.func,
  onPressDelete: PropTypes.func,
  onPressCopy: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.object,
};

EditableWrapper.defaultProps = {
  editable: false,
  sequence: 0,
  onPressEdit: () => {},
  children: null,
  sx: {},
};

export default EditableWrapper;

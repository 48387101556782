import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPage, updatePage, createPage, getLayouts } from 'api/pages';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import BigBreadcrumb from 'components/BigBreadcrumb';
import { useAuth } from 'contexts/auth';
import { useProject } from 'contexts/project';
import EditPageForm from 'pages/EditPage/EditPageForm';
import { handleErrorResponse } from 'utils/general';
import PageSections from './pageSections';

const PageSummarySection = () => {
  const [page, setPage] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('appId');
  const pageId = searchParams.get('pageId');
  const { project } = useProject();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchLayoutsFromApi = useCallback(() => {
    const layoutParams = {};
    return getLayouts(layoutParams)
      .then(({ data }) => {
        setLayouts(data);
      });
  }, []);

  const fetchPageFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const pageParams = {};
    return getPage(pageId, pageParams)
      .then(({ data }) => {
        setPage(data);
      });
  }, [pageId]);

  const onClickCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const pageBody = {
      app: appId,
      layout: values.layout,
      path: (values.path || '').startsWith('/') ? values.path : `/${values.path}`,
      background_color: values.background_color,
      background_image_url: values.background_image_url,
      refresh_rate: values.refresh_rate,
    };
    return (pageId ? updatePage(pageId, pageBody) : createPage(pageBody))
      .then(({ data }) => {
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [pageId, appId, navigate]);

  useEffect(() => {
    Promise.all([
      fetchLayoutsFromApi(),
      fetchPageFromApi(),
    ]).catch((err) => {
      handleErrorResponse(err, setAuth);
    });
  }, [fetchLayoutsFromApi, fetchPageFromApi, setAuth]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Apps', link: '/apps' },
            { label: project?.display_name, to: `/webpage/app_summary?appId=${appId}` },
            { label: page?.path },
          ]}
          sx={{ mb: 2 }}
        />
        <EditPageForm
          page={page}
          layouts={layouts}
          onSubmit={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        <PageSections
          appId={appId}
          pageId={pageId}
        />
      </Container>
    </MKBox>
  );
};

export default PageSummarySection;

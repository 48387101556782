import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import React, { useMemo, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useProject } from 'contexts/project';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const WebPagesMenu = ({ open, setOpen }) => {
  const { project } = useProject();
  const navigate = useNavigate();
  const isOpen = useMemo(() => (open === 'webpages'), [open]);
  const [selectedPage, setSelectedPage] = useState(null);

  const webpages = useMemo(() => {
    if (!project || !project.pages || !Array.isArray(project.pages)) return [];
    const pages = [];
    project.pages.forEach((page) => {
      if (page) {
        pages.push(page);
      }
    });
    return pages;
  }, [project]);

  const trimSlash = (path) => {
    if (path === '/') return '/';
    if (path && path[0] === '/') {
      return path.substring(1);
    }
    return path;
  };

  const handleRedirect = (page) => {
    setSelectedPage(page);
    navigate('/webpage/', { state: { page } });
  };

  const handleMenuClick = () => {
    if (open === 'webpages') { setOpen(null); }
    if (open !== 'webpages') { setOpen('webpages'); }
    setSelectedPage(null);
    navigate('/webpage/', { state: { page: null } });
  };

  return (
    <MKBox>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => { handleMenuClick(); }}
        p={2}
        sx={{ 'cursor': 'pointer',
          '&:hover': { backgroundColor: 'lightgray' },
          'backgroundColor': isOpen ? 'lightgray' : 'white',
        }}
      >
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          Web Pages
        </MKTypography>
        { isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
      </MKBox>
      { isOpen && (
        <MKBox>
          {webpages.map((page) => (
            <MKBox
              pl={4}
              py={1}
              key={page.page_id}
              onClick={() => handleRedirect(page)}
              sx={{
                'cursor': 'pointer',
                '&:hover': { backgroundColor: 'lightgray' },
                'backgroundColor': selectedPage?.page_id === page.page_id ? 'lightgray' : 'white',
              }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px">
                {trimSlash(page.path)}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>
      )}
    </MKBox>

  );
};

WebPagesMenu.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.func,
};

WebPagesMenu.defaultProps = {
  open: null,
  setOpen: () => {},
};

export default WebPagesMenu;

import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import InputField, {
  INPUT_TYPE_BOOLEAN, INPUT_TYPE_NUMBER, INPUT_TYPE_TEXT, INPUT_TYPE_RICH_TEXT, INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_COLOR, INPUT_TYPE_SIZE, INPUT_TYPE_ALIGNMENT, INPUT_TYPE_JUSTIFY_CONTENT, INPUT_TYPE_FLEX_DIRECTION,
  INPUT_TYPE_ALIGN_ITEMS, INPUT_TYPE_BUTTON_VARIANT, INPUT_TYPE_TEXT_VARIANT, INPUT_TYPE_OBJECT_FIT, INPUT_TYPE_OBJECT_POSITION,
  INPUT_TYPE_FONT_WEIGHT,
} from 'components/InputField';

const EditAttributeField = ({ attribute, value, setFieldValue, handleChange, handleBlur, disabled, ...props }) => {
  const { attribute_id, name, data_type, remark } = attribute || {};
  let inputType = null;
  switch (data_type) {
    case 1: // BOOLEAN
      inputType = INPUT_TYPE_BOOLEAN;
      break;
    case 2: // NUMBER
      inputType = INPUT_TYPE_NUMBER;
      break;
    case 3: // RICH TEXT
      inputType = INPUT_TYPE_RICH_TEXT;
      break;
    case 4: // TEXT
      inputType = INPUT_TYPE_TEXT;
      break;
    case 5: // COLOR
      inputType = INPUT_TYPE_COLOR;
      break;
    case 6: // SIZE 
      inputType = INPUT_TYPE_SIZE;
      break;
    case 7: // ALIGNMENT
      inputType = INPUT_TYPE_ALIGNMENT;
      break;
    case 8: // JSON
      inputType = INPUT_TYPE_TEXTAREA;
      break;
    case 9: // ATTRIBUTE
      inputType = INPUT_TYPE_JUSTIFY_CONTENT;
      break;
    case 10: // ATTRIBUTE
      inputType = INPUT_TYPE_ALIGN_ITEMS;
      break;
    case 11: // ATTRIBUTE
      inputType = INPUT_TYPE_FLEX_DIRECTION;
      break;
    case 12: // ATTRIBUTE
      inputType = INPUT_TYPE_BUTTON_VARIANT;
      break;
    case 13: // ATTRIBUTE
      inputType = INPUT_TYPE_TEXT_VARIANT;
      break;
    case 14: // ATTRIBUTE
      inputType = INPUT_TYPE_OBJECT_FIT;
      break;
    case 15: // ATTRIBUTE
      inputType = INPUT_TYPE_OBJECT_POSITION;
      break;
    case 16: // ATTRIBUTE
      inputType = INPUT_TYPE_FONT_WEIGHT;
      break;
    default:
      return null;
  }
  return (
    <InputField
      name={attribute_id}
      label={`${startCase(name)}${remark ? ` (${remark})` : ''}`}
      value={value}
      type={inputType}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      disabled={disabled}
      {...props}
    />
  );
};

EditAttributeField.propTypes = {
  attribute: PropTypes.shape({
    attribute_id: PropTypes.string,
    name: PropTypes.string,
    data_type: PropTypes.number,
  }),
  value: PropTypes.any,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

EditAttributeField.defaultProps = {
  attribute: null,
  value: null,
  setFieldValue: () => { },
  handleChange: () => { },
  handleBlur: () => { },
  disabled: false,
};

export default EditAttributeField;

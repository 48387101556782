import api from 'api';

export const getSurveys = (params) => {
  return api.get('/surveys', params, 'bearer');
};

export const getSurvey = (id, params) => {
  return api.get(`/surveys/${id}`, params, 'bearer');
};

export const createSurvey = (body) => {
  return api.post('/surveys', body, 'bearer');
};

export const updateSurvey = (id, body) => {
  return api.put(`/surveys/${id}`, body, 'bearer');
};

export const deleteSurvey = (id) => {
  return api.delete(`/surveys/${id}`, {}, 'bearer');
};

export const getSurveyQuestions = (params) => {
  return api.get('/survey_questions', params, 'bearer');
};

export const createSurveyQuestion = (body) => {
  return api.post('/survey_questions', body, 'bearer');
};

export const updateSurveyQuestion = (id, body) => {
  return api.put(`/survey_questions/${id}`, body, 'bearer');
};

export const deleteSurveyQuestion = (id) => {
  return api.delete(`/survey_questions/${id}`, {}, 'bearer');
};

export const getSurveyQuestionOptions = (params) => {
  return api.get('/survey_question_choices', params, 'bearer');
};

export const createSurveyQuestionOption = (body) => {
  return api.post('/survey_question_choices', body, 'bearer');
};

export const updateSurveyQuestionOption = (id, body) => {
  return api.put(`/survey_question_choices/${id}`, body, 'bearer');
};

export const createSurveyAnswerSubmission = (body) => {
  return api.post('/survey_question_answer_submissions', body, 'bearer');
};

export const getSurveyAnswerSubmission = (params) => {
  return api.get('/survey_question_answer_submissions', params, 'bearer');
};

export const createSurveyAnswer = (body) => {
  return api.post('/survey_question_answers', body, 'bearer');
};

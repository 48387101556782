import React from 'react';
import BusinessLogicListViewSection from 'sections/BusinessLogicListView';
import MKBox from 'components/MaterialKit/MKBox';

const SettingsSection = ({ ...props }) => {
  return (
    <MKBox {...props}>
      <BusinessLogicListViewSection />
    </MKBox>
  );
};

export default SettingsSection;

import MKBox from 'components/MaterialKit/MKBox';
import React, { useCallback, useEffect, useState } from 'react';
import { getLayouts, createPage, updatePage, getPage } from 'api/pages';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import MKTypography from 'components/MaterialKit/MKTypography';
import PagesMenuForm from './pagesMenuForm';
import PagesMenuSequencer from './pagesMenuSequencer';

const PagesMenu = ({ pageId }) => {
  console.log('Received id:', pageId)
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [layouts, setLayouts] = useState([]);
  const [page, setPage] = useState(null);

  const fetchPageFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const pageParams = {};
    return getPage(pageId, pageParams)
      .then(({ data }) => {
        setPage(data);
      });
  }, [pageId]);

  const fetchLayoutsFromApi = useCallback(() => {
    const layoutParams = {};
    return getLayouts(layoutParams)
      .then(({ data }) => {
        setLayouts(data);
      });
  }, []);

  const onClickSaveToApi = useCallback((values, { setFieldError, resetForm }) => {
    const pageBody = {
      app: page?.app?.app_id,
      layout: values.layout,
      path: (values.path || '').startsWith('/') ? values.path : `/${values.path}`,
      background_color: values.background_color,
      background_image_url: values.background_image_url,
      refresh_rate: values.refresh_rate,
    };
    return (page?.page_id ? updatePage(page?.page_id, pageBody) : createPage(pageBody))
      .then(({ data }) => {
        console.log('Page successfully updated.');
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [page?.app?.app_id, page?.page_id]);

  useEffect(() => {
    Promise.all([
      fetchLayoutsFromApi(),
      fetchPageFromApi(),
    ]).catch((err) => {
      handleErrorResponse(err, setAuth);
    });
  }, [fetchLayoutsFromApi, fetchPageFromApi, setAuth]);

  return (
    <MKBox display="flex" flexDirection="column" sx={{ width: '100%', height: '100%' }}>
      <MKBox sx={{ mt: 1, pb: 2 }}>
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          Edit Page
        </MKTypography>
      </MKBox>
      <PagesMenuForm
        page={page}
        layouts={layouts}
        onSubmit={onClickSaveToApi}
      />
      <PagesMenuSequencer
        page={page}
      />
    </MKBox>
  );
};

PagesMenu.propTypes = {
  pageId: PropTypes.string,
};

export default PagesMenu;

import { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import toLower from 'lodash/toLower';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import Grid from '@mui/material/Grid';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import EditAttributeField from 'components/EditAttributeField';
import Button from 'components/Button';
import Select from 'components/Select';
import { getDynamicTableRows } from 'api/sections';
import { handleErrorResponse, isUuid, parseJSON } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getFiles } from 'api/files';

const ATTRIBUTE_SUFFIX_IS_REDIRECT = '.is_redirect';
const ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID = '.target_collection_definition_id';
const ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID = '.target_attribute_id';

const ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION = '.is_from_collection';
const ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID = '.from_collection_definition_id';
const ATTRIBUTE_SUFFIX_FILE_URL = '.file_url';
const ATTRIBUTE_VALUE_ID = 'value';
const ATTRIBUTE_NAME = 'name';

const ElementEditAttributesForm = ({ attributes, config, collectionDefinitions, onSave, onCancel, ...props }) => {
  const [fromCollectionSelectOptions, setFromCollectionSelectOptions] = useState([]);
  const { auth, setAuth } = useAuth();
  const [preselectedFromCollection, setPreselectedFromCollection] = useState(null);

  const fetchAssetsOptionsFromCollection = useCallback((collectionId) => {
    const tmpCol = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === collectionId);
    if (!tmpCol) return Promise.resolve();
    const value = tmpCol?.attributes.find((attr) => attr.name === ATTRIBUTE_VALUE_ID)?.attribute_id;
    const name = tmpCol?.attributes.find((attr) => attr.name === ATTRIBUTE_NAME)?.attribute_id;
    return getDynamicTableRows(collectionId, {})
      .then(({ data }) => {
        const fileIds = data.map((row) => {
          const jsonData = parseJSON(row.json_short_data, {});
          if (!jsonData[value] || !jsonData[name]) { return; } // Omit return statement for cases where id or name is missing
          return { id: jsonData[value], name: jsonData[name] };
        }).filter(Boolean); // Filter out undefined values
        return fileIds;
      })
      .then((fileIds) => {
        if (!fileIds.length) {
          // eslint-disable-next-line no-alert
          alert('Invalid collection definition. Does not have any options available. Please try another one, or check what may be wrong.');
          return Promise.resolve([]);
        }
        const files = getFiles({ 'file_id[in]': fileIds.map((file) => file.id).join(',') })
          .then(({ data }) => {
            return data.map((file) => {
              const option = {};
              option.label = fileIds.find((f) => f.id === file.file_id).name;
              option.value = file.file_url;
              return option;
            });
          });
        return files;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinitions, setAuth]);

  const onFromCollectionChange = useCallback((v) => {
    fetchAssetsOptionsFromCollection(v)
      .then((options) => {
        setFromCollectionSelectOptions(options || []);
      });
  }, [fetchAssetsOptionsFromCollection]);

  const initialValues = useMemo(() => {
    return (attributes || []).reduce((map, attr) => {
      const { attribute_id, default_value } = attr;
      const updatedMap = { ...map };
      updatedMap[attribute_id] = (config && attribute_id in config) ? config[attribute_id] : default_value;
      return updatedMap;
    }, {});
  }, [attributes, config]);

  const [mainAttributes, redirectAttributes] = useMemo(() => partition(attributes, (attribute) => {
    const attributeName = attribute?.name || '';
    return !(attributeName.endsWith(ATTRIBUTE_SUFFIX_IS_REDIRECT)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID)
    );
  }), [attributes]);

  const [restAttributes, fromCollectionAttributes] = useMemo(() => partition(mainAttributes, (attribute) => {
    const attributeName = attribute?.name || '';
    return !(attributeName.endsWith(ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION) || attributeName.endsWith(ATTRIBUTE_SUFFIX_FILE_URL) || attributeName.endsWith(ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID));
  }), [mainAttributes]);

  const getTreeOfOptions = (definitions) => {
    return definitions.map((collectionDefinition) => {
      const children = getTreeOfOptions(definitions.filter((cd) => cd.parent_collection_definition === collectionDefinition.collection_definition_id));
      return {
        label: collectionDefinition.name,
        value: collectionDefinition.collection_definition_id,
        children: children.length ? children : undefined,
      };
    });
  };

  useEffect(() => {
    const fromCollectionAttributeId = attributes.find((attr) => attr.name.endsWith(ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID))?.attribute_id;
    setPreselectedFromCollection(initialValues[fromCollectionAttributeId]);
  }, [attributes, initialValues]);

  useEffect(() => {
    if (isUuid(preselectedFromCollection)) {
      fetchAssetsOptionsFromCollection(preselectedFromCollection)
        .then((options) => {
          setFromCollectionSelectOptions(options || []);
        });
    }
  }, [preselectedFromCollection, fetchAssetsOptionsFromCollection]);

  const ifcCollectionDefinitionOptions = useMemo(() => getTreeOfOptions(collectionDefinitions.filter((cd) => cd.parent_collection_definition)), [collectionDefinitions]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
              <Grid item xs={12}>
                {errors.form && (
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                    &nbsp;
                  </MKTypography>
                )}
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      {dirty ? 'Cancel' : 'Back'}
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} sx={{ ml: 1 }}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
            {(restAttributes || []).sort(
              (a1, a2) => a1.sequence - a2.sequence,
            ).map((attribute) => {
              const { attribute_id, name } = attribute;
              const isRedirectAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_IS_REDIRECT}`);
              const targetCollectionDefinitionIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID}`);
              const targetAttributeIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID}`);

              const isCollAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION}`);
              const ifcCollectionDefinitionIdAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID}`);
              const ifcAttributeIdAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_FILE_URL}`);

              const isRedirect = toLower(values[isRedirectAttribute?.attribute_id]) === 'true';
              const isFromCollection = toLower(values[isCollAttribute?.attribute_id]) === 'true';

              const targetCollectionDefinition = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === values[targetCollectionDefinitionIdAttribute?.attribute_id]);
              const ifcCollectionDefinition = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === values[ifcCollectionDefinitionIdAttribute?.attribute_id]);

              const targetCollectionDefinitionOptions = collectionDefinitions.map((collectionDefinition) => ({
                label: collectionDefinition.name,
                value: collectionDefinition.collection_definition_id,
              }));

              const targetCollectionDefinitionAttributeOptions = [
                {
                  label: 'ID',
                  value: 'id',
                },
                ...(targetCollectionDefinition?.attributes || []).map((targetAttribute) => ({
                  label: targetAttribute.name === '__system_order__' ? '# System Order' : targetAttribute.name,
                  value: targetAttribute.attribute_id,
                })),
                {
                  label: 'Created Date',
                  value: 'createddate',
                },
                {
                  label: 'Last Modified Date',
                  value: 'lastmoddate',
                },
              ];

              return (
                <MKBox key={attribute_id} mb={2}>
                  <MKBox display="flex" flexDirection="column">
                    <MKBox flex={1}>
                      <EditAttributeField
                        attribute={attribute}
                        value={!isFromCollection ? values[attribute_id] : '-'}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={isFromCollection}
                      />
                    </MKBox>
                    {!!isRedirectAttribute && (
                      <MKBox mt={1} flex={1}>
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <EditAttributeField
                            attribute={isRedirectAttribute}
                            value={values[isRedirectAttribute.attribute_id]}
                            label={startCase(isRedirectAttribute.name.replace(`${name}.`, ''))}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </MKBox>
                      </MKBox>
                    )}
                    {!!isCollAttribute && (
                      <MKBox mt={1} flex={1}>
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <EditAttributeField
                            attribute={isCollAttribute}
                            value={values[isCollAttribute.attribute_id]}
                            label={startCase(isCollAttribute.name.replace(`${name}.`, ''))}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </MKBox>
                      </MKBox>
                    )}
                  </MKBox>
                  {isRedirect ? (
                    <MKBox mt={1} ml={2}>
                      {!!targetCollectionDefinitionIdAttribute && (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetCollectionDefinitionIdAttribute.attribute_id}
                            label={startCase(targetCollectionDefinitionIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetCollectionDefinitionIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetCollectionDefinitionIdAttribute.attribute_id, v)}
                            options={targetCollectionDefinitionOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                      {!!targetAttributeIdAttribute && (
                        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetAttributeIdAttribute.attribute_id}
                            label={startCase(targetAttributeIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetAttributeIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetAttributeIdAttribute.attribute_id, v)}
                            disabled={!targetCollectionDefinition?.attributes?.length}
                            options={targetCollectionDefinitionAttributeOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                    </MKBox>
                  ) : null}
                  {isFromCollection ? (
                    <MKBox mt={1} ml={2}>
                      {!!ifcCollectionDefinitionIdAttribute && (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={ifcCollectionDefinitionIdAttribute.attribute_id}
                            label={startCase(ifcCollectionDefinitionIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[ifcCollectionDefinitionIdAttribute.attribute_id] || ''}
                            onChange={(v) => { onFromCollectionChange(v); setFieldValue(ifcCollectionDefinitionIdAttribute.attribute_id, v); }}
                            options={ifcCollectionDefinitionOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                      {!!ifcAttributeIdAttribute && (
                        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={ifcAttributeIdAttribute.attribute_id}
                            label={startCase(ifcAttributeIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[ifcAttributeIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(ifcAttributeIdAttribute.attribute_id, v)}
                            disabled={!ifcCollectionDefinition?.attributes?.length}
                            options={fromCollectionSelectOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                    </MKBox>
                  ) : null}
                </MKBox>
              );
            })}
          </MKBox>
        );
      }}
    </Formik>
  );
};

ElementEditAttributesForm.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute_id: PropTypes.string,
      name: PropTypes.any,
      data_type: PropTypes.number,
      default_value: PropTypes.any,
    }),
  ),
  config: PropTypes.object,
  collectionDefinitions: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

ElementEditAttributesForm.defaultProps = {
  attributes: [],
  config: {},
  collectionDefinitions: [],
  onSave: () => { },
  onCancel: () => { },
};

export default ElementEditAttributesForm;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField, { INPUT_TYPE_SELECT } from 'components/InputField';
import Button from 'components/Button';

const SurveyForm = ({ survey, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    name: survey?.name || '',
    description: survey?.description || '',
    app: survey?.app || '',
  }), [survey]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, touched, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={0}>
              <Grid container item xs={12}>
                <InputField
                  name="name"
                  label="Name"
                  value={values.name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.name && !!errors.name}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.name && !!errors.name) ? errors.name : '\xa0'}
                </MKTypography>
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="description"
                  label="Description"
                  value={values.description}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.description && !!errors.description}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.description && !!errors.description) ? errors.description : '\xa0'}
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

SurveyForm.propTypes = {
  survey: PropTypes.object,
  onSave: PropTypes.func,
};

SurveyForm.defaultProps = {
  survey: {},
  onSave: () => {},
};

export default SurveyForm;

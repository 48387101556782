import get from 'lodash/get';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import AppEditBarSection from 'sections/AppEditBar';
import LayoutEditBarSection from 'sections/LayoutEditBar';
import { getPublicApps } from 'api/apps';
import { getPublicPages } from 'api/pages';
import { getPublicMenu, getPublicMenuItemDependencies } from 'api/menus';
import { useApp } from 'contexts/app';
import { useAuth } from 'contexts/auth';
import { useProject } from 'contexts/project';
import { MenuProvider } from 'contexts/menu';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DrawerMenu from 'components/DrawerMenu';
import EditableWrapper from 'components/EditableWrapper';
import ChooseProjectSection from 'sections/ChooseProject';
import { Container } from '@mui/material';

const renderNotFoundPage = (isLoaded) => (
  <MKBox display="flex" justifyContent="center" alignItems="center" height="100vh">
    {isLoaded ? (
      <MKTypography variant="h1" color="dark" textGradient fontSize="40vw">404</MKTypography>
    ) : (
      <CircularProgress size="4rem" color="secondary" thickness={6} />
    )}
  </MKBox>
);

const UniversalPage = () => {
  const [page, setPage] = useState(null);
  const [menu, setMenu] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [sectionsProps, setSectionsProps] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageLoaded, setPageLoaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { app, setApp } = useApp();
  const { auth, setAuth } = useAuth();
  const { project, setProject } = useProject();

  const isLocationAdmin = window.location.pathname.startsWith('/admin');

  const userRoleScopes = useMemo(() => auth.user?.role?.scopes || [], [auth.user]);
  const hasAuthorModeAccess = useMemo(() => !!userRoleScopes.find(({ scope }) => scope === 'author_mode'), [userRoleScopes]);

  const layout = useMemo(() => get(page, 'layout') || {}, [page]);
  const sections = useMemo(() => get(page, 'sections') || [], [page]);
  const isAuthorMode = useMemo(() => (hasAuthorModeAccess && searchParams.get('mode') === 'author'), [hasAuthorModeAccess, searchParams]);

  const fetchAppFromApi = useCallback(() => {
    const urlParts = (location.pathname || '').split('/');
    const appBaseUrl = `/${urlParts[1]}`;
    const appParams = {
      base_url: appBaseUrl,
    };
    return getPublicApps(appParams)
      .then(({ data }) => {
        if (data.length) {
          setApp(data[0]);
        } else {
          const retryAppParams = {
            base_url: '/',
          };
          return getPublicApps(retryAppParams)
            .then(({ data: retryData }) => {
              if (retryData.length) {
                setApp(retryData[0]);
              } else {
                setApp(null);
              }
            });
        }
      });
  }, [location.pathname, setApp]);

  const fetchPageFromApi = useCallback(() => {
    setPageLoaded(false);
    const subUrl = location.pathname;
    const appBaseUrl = app?.base_url;
    const pageParams = {
      ...(app ? {
        app: app.app_id,
      } : {}),
      path: subUrl.replace(/\/+$/, '') || '/',
      $expand: 'app,layout/header/section_definition/collection_definition/attributes,layout/footer/section_definition/collection_definition/attributes,sections/section_definition/collection_definition/attributes',
      $orderBy: 'createddate desc',
    };
    return getPublicPages(pageParams)
      .then(({ data }) => {
        if (!data.length) {
          setPage(null);
        } else if ((appBaseUrl !== '/' ? `${appBaseUrl}${subUrl}` : subUrl).replace(/\/+$/, '') === window.location.pathname.replace(/\/+$/, '')) {
          setPage(data[0]);
        }
        setPageLoaded(true);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [location.pathname, app, setAuth]);

  const fetchSectionDataFromApi = useCallback((section) => {
    const { section_id } = section;
    return fetchSectionProps(section, !auth.user)
      .then((sectionProps) => {
        setSectionsProps((oriSectionsProps) => {
          const updatedSectionsProps = { ...oriSectionsProps };
          updatedSectionsProps[section_id] = sectionProps;
          return updatedSectionsProps;
        });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth.user, setAuth]);

  const fetchMenuItemsFromApi = useCallback((menuNodeId) => {
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $expand: 'menu_item',
      $orderBy: 'sequence',
    };
    return getPublicMenuItemDependencies(menuItemDependencyParams)
      .then(({ data: menuItemDependencies }) => {
        const menuItems = (menuItemDependencies || []).map(({ menu_item }) => menu_item);
        return menuItems;
      });
  }, []);

  const fetchRecursiveMenuItemsFromApi = useCallback(async (menuNodeId) => {
    const menuItems = await fetchMenuItemsFromApi(menuNodeId);
    const updatedMenuItems = await menuItems.reduce(async (itemsPromise, menuItem) => {
      const { menu_item_id, type, scope, disabled } = menuItem;
      const prevItems = await itemsPromise;
      if (disabled) {
        return prevItems;
      }
      const hasAccess = !scope || !!userRoleScopes.find((roleScope) => roleScope.scope === scope);
      if (!hasAccess) {
        return prevItems;
      }
      if (type === 1) {
        const menuSubItems = await fetchRecursiveMenuItemsFromApi(menu_item_id);
        const updatedMenuItem = {
          ...menuItem,
          menu_items: menuSubItems,
        };
        return [...prevItems, updatedMenuItem];
      }
      return [...prevItems, menuItem];
    }, Promise.resolve([]));
    return updatedMenuItems;
  }, [userRoleScopes, fetchMenuItemsFromApi]);

  const fetchMenuFromApi = useCallback(() => {
    if (layout?.menu) {
      const menuParams = {
        $expand: 'menu_type,menu_node',
      };
      return getPublicMenu(layout.menu, menuParams)
        .then(async ({ data }) => {
          const { menu_node } = data;
          if (menu_node?.type === 1) {
            const aggregatedMenuItems = await fetchRecursiveMenuItemsFromApi(menu_node?.menu_item_id);
            setMenu({
              ...data,
              menu_node: {
                ...menu_node,
                menu_items: aggregatedMenuItems,
              },
            });
          }
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
    setMenu(null);
  }, [layout.menu, fetchRecursiveMenuItemsFromApi, setAuth]);

  const onPressEditSection = useCallback((sectionId) => {
    navigate(`/section-attributes/${sectionId}`);
  }, [navigate]);

  const onPressEditApp = useCallback(() => {
    window.location.assign(`/app/${app?.app_id}`);
  }, [app]);

  const onPressEditPage = useCallback(() => {
    window.location.assign(`/app/${app?.app_id}/page/${page?.page_id}`);
  }, [page, app]);

  const onPressEditMenu = useCallback((menuId) => {
    window.location.assign(`/app/${app?.app_id}/menu/${menuId}`);
  }, [app]);

  const onToggleAuthorMode = useCallback(() => {
    setSearchParams((params) => {
      params.set('mode', isAuthorMode ? 'user' : 'author');
      return params;
    }, { replace: true });
  }, [isAuthorMode, setSearchParams]);

  useEffect(() => {
    if (searchParams.get('mode') === 'author') {
      if (!auth.user) {
        navigate(`/login?redirect=${location.pathname}${location.search}`);
      } else if (!hasAuthorModeAccess) {
        setSearchParams((params) => {
          params.set('mode', 'user');
          return params;
        }, { replace: true });
      }
    }
  }, [auth.user, hasAuthorModeAccess, location, searchParams, setSearchParams, navigate]);

  useEffect(() => {
    if (app && auth.required) {
      setAuth((oriAuth) => ({
        ...oriAuth,
        required: false,
      }));
      if (location.pathname !== '/login') {
        navigate(`/login?redirect=${location.pathname}${location.search}`);
      }
    }
  }, [app, location, auth.required, navigate, setAuth]);

  useEffect(() => {
    const totalSections = [
      ...(layout?.header ? [layout.header] : []),
      ...(layout?.footer ? [layout.footer] : []),
      ...sections,
    ];
    setSectionsProps((oriSectionsProps) => {
      return (Object.keys(oriSectionsProps)).reduce((prevSectionProps, sectionId) => {
        const updatedSectionsProps = { ...prevSectionProps };
        const existingSection = totalSections.find((section) => section.section_id === sectionId);
        if (existingSection) {
          updatedSectionsProps[sectionId] = oriSectionsProps[sectionId];
        }
        return updatedSectionsProps;
      }, {});
    });
    (totalSections || []).forEach((section) => {
      fetchSectionDataFromApi(section);
    });
  }, [layout, sections, fetchSectionDataFromApi]);

  useEffect(() => {
    fetchMenuFromApi();
  }, [fetchMenuFromApi]);

  useEffect(() => {
    if (app) {
      fetchPageFromApi();
    } else {
      fetchAppFromApi();
    }
  }, [app, fetchAppFromApi, fetchPageFromApi]);

  useEffect(() => {
    if (!isAuthorMode && (typeof page?.refresh_rate === 'number') && page?.refresh_rate > 0) {
      const numberOfSec = (page?.refresh_rate || 0) * 1000;
      const intervalTimerId = setInterval(() => {
        fetchPageFromApi();
      }, numberOfSec);
      return () => {
        clearInterval(intervalTimerId);
      };
    }
  }, [fetchPageFromApi, isAuthorMode, page?.refresh_rate]);

  return page ? (
    <MKBox display="flex" flexDirection="column" sx={{ height: '100%' }}>
      {isAuthorMode && (
        <MKBox bgColor="light" shadow="md" sx={{ zIndex: 1 }}>
          <EditableWrapper editable onPressEdit={onPressEditApp}>
            <AppEditBarSection app={app} />
          </EditableWrapper>
          <EditableWrapper editable onPressEdit={onPressEditPage}>
            <LayoutEditBarSection layout={page?.layout} />
          </EditableWrapper>
        </MKBox>
      )}
      <MenuProvider value={{ menu, setMenu, isOpen: isMenuOpen, setOpen: setMenuOpen }}>
        <MKBox
          flex={1}
          display="flex"
          sx={{
            backgroundImage: `url(${page?.background_image_url})`,
            backgroundColor: page?.background_color,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          {['sidebar', 'drawer'].includes(menu?.menu_type?.name) && (
            <EditableWrapper
              editable={isAuthorMode}
              onPressEdit={() => onPressEditMenu(menu?.menu_id)}
              sx={{
                '.MuiDrawer-paper': {
                  position: 'relative',
                },
              }}
            >
              <DrawerMenu menu={menu} />
            </EditableWrapper>
          )}

          <MKBox display="flex" flexDirection="column" flex={1} sx={{ minWidth: 0 }}>
            {renderSectionComponent(layout?.header, sectionsProps[layout?.header?.section_id], isAuthorMode, onPressEditSection)}
            <MKBox flex={1}>
              {sections.sort((s1, s2) => s1.sequence - s2.sequence).map((section, idx) => {
                return (
                  <Fragment key={`${section.section_id}-${idx}`}>
                    {renderSectionComponent(section, sectionsProps[section.section_id], isAuthorMode, onPressEditSection)}
                  </Fragment>
                );
              })}
            </MKBox>
            {renderSectionComponent(layout?.footer, sectionsProps[layout?.footer?.section_id], isAuthorMode, onPressEditSection)}
          </MKBox>

          {hasAuthorModeAccess && (
            <MKBox
              display="flex"
              alignItems="center"
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              p={1}
              sx={{ position: 'fixed', bottom: 20, left: 20, zIndex: 1500 }}
            >
              <Switch checked={isAuthorMode} onChange={onToggleAuthorMode} />
              <MKBox>
                <MKTypography fontWeight="bold" lineHeight={1} color={isAuthorMode ? 'success' : 'secondary'}>
                  Author
                </MKTypography>
                <MKTypography variant="subtitle2" lineHeight={1} color={isAuthorMode ? 'success' : 'secondary'}>
                  Mode
                </MKTypography>
              </MKBox>
            </MKBox>
          )}
        </MKBox>
      </MenuProvider>
    </MKBox>
  ) : renderNotFoundPage(pageLoaded);
};

export default UniversalPage;

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import RichTextEditor from 'components/RichTextEditor';
import MKDatePicker from 'components/MaterialKit/MKDatePicker';
import FileUploaderSection from 'sections/FileUploader';
import { datetimeFormatter, DATE_FORMAT_DATE_TIME_DEFAULT } from 'utils/datetime';

export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TEXTAREA = 'textarea';
export const INPUT_TYPE_RICH_TEXT = 'rich_text';
export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_SELECT = 'select';
export const INPUT_TYPE_BOOLEAN = 'boolean';
export const INPUT_TYPE_FILE = 'file';
export const INPUT_TYPE_IMAGE = 'image';
export const INPUT_TYPE_VIDEO = 'video';
export const INPUT_TYPE_AUDIO = 'audio';
export const INPUT_TYPE_DATETIME = 'datetime';
export const INPUT_TYPE_COLOR = 'color';
export const INPUT_TYPE_SIZE = 'size';
export const INPUT_TYPE_ALIGNMENT = 'alignment';
export const INPUT_TYPE_JUSTIFY_CONTENT = 'justify_content';
export const INPUT_TYPE_ALIGN_ITEMS = 'align_items';
export const INPUT_TYPE_FLEX_DIRECTION = 'flex_direction';
export const INPUT_TYPE_BUTTON_VARIANT = 'button_variant';
export const INPUT_TYPE_TEXT_VARIANT = 'text_variant';
export const INPUT_TYPE_OBJECT_FIT = 'object_fit';
export const INPUT_TYPE_OBJECT_POSITION = 'object_position';
export const INPUT_TYPE_FONT_WEIGHT = 'font_weight';

export const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const sizeOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];

export const alignmentOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
];

export const justifyContentOptions = [
  { label: 'Start', value: 'flex-start' },
  { label: 'Center', value: 'center' },
  { label: 'End', value: 'flex-end' },
  { label: 'Space Between', value: 'space-between' },
  { label: 'Space Around', value: 'space-around' },
];

export const alignItemsOptions = [
  { label: 'Start', value: 'flex-start' },
  { label: 'Center', value: 'center' },
  { label: 'End', value: 'flex-end' },
  { label: 'Stretch', value: 'stretch' },
];

export const flexDirectionOptions = [
  { label: 'Row', value: 'row' },
  { label: 'Row Reverse', value: 'row-reverse' },
  { label: 'Column', value: 'column' },
  { label: 'Column Reverse', value: 'column-reverse' },
];

export const buttonVariantOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Outlined', value: 'outlined' },
  { label: 'Contained', value: 'contained' },
];

export const textVariantOptions = [
  { label: 'H1', value: 'h1' },
  { label: 'H2', value: 'h2' },
  { label: 'H3', value: 'h3' },
  { label: 'H4', value: 'h4' },
  { label: 'H5', value: 'h5' },
  { label: 'H6', value: 'h6' },
  { label: 'Subtitle1', value: 'subtitle1' },
  { label: 'Subtitle2', value: 'subtitle2' },
  { label: 'Body1', value: 'body1' },
  { label: 'Body2', value: 'body2' },
  { label: 'Caption', value: 'caption' },
  { label: 'Button', value: 'button' },
  { label: 'Overline', value: 'overline' },
];

export const objectFitOptions = [
  { label: 'Fill', value: 'fill' },
  { label: 'Contain', value: 'contain' },
  { label: 'Cover', value: 'cover' },
  { label: 'None', value: 'none' },
  { label: 'Scale Down', value: 'scale-down' },
];

export const objectPositionOptions = [
  { label: 'Top', value: 'top' },
  { label: 'Top Right', value: 'top right' },
  { label: 'Right', value: 'right' },
  { label: 'Bottom Right', value: 'bottom right' },
  { label: 'Bottom', value: 'bottom' },
  { label: 'Bottom Left', value: 'bottom left' },
  { label: 'Left', value: 'left' },
  { label: 'Top Left', value: 'top left' },
  { label: 'Center', value: 'center' },
];

export const fontWeightOptions = [
  { label: 'Regular', value: 'regular' },
  { label: 'Bold', value: 'bold' },
];

const getAcceptFileTypes = (inputType) => {
  switch (inputType) {
    case INPUT_TYPE_IMAGE:
      return ['image/*'];
    case INPUT_TYPE_VIDEO:
      return ['video/*'];
    case INPUT_TYPE_AUDIO:
      return ['audio/*'];
    default:
      return ['*/*'];
  }
};

const InputField = ({ name, value, label, type, options, handleChange, setFieldValue, handleBlur, disabled, ...props }) => {
  switch (type) {
    case INPUT_TYPE_BOOLEAN:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={booleanOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_SIZE:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={sizeOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_ALIGNMENT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={alignmentOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_JUSTIFY_CONTENT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={justifyContentOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_ALIGN_ITEMS:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={alignItemsOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_FLEX_DIRECTION:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={flexDirectionOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_BUTTON_VARIANT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={buttonVariantOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_TEXT_VARIANT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={textVariantOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_OBJECT_FIT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={objectFitOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_OBJECT_POSITION:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={objectPositionOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_FONT_WEIGHT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={fontWeightOptions}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_SELECT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={type === INPUT_TYPE_BOOLEAN ? booleanOptions : options}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_DATETIME:
      return (
        <MKDatePicker
          name={name}
          value={value || null}
          onChange={([v]) => setFieldValue(name, v.valueOf())}
          input={{
            name,
            label,
            fullWidth: true,
            value: value ? datetimeFormatter(value, DATE_FORMAT_DATE_TIME_DEFAULT) : '',
            disabled,
          }}
          options={{
            enableTime: true,
            dateFormat: DATE_FORMAT_DATE_TIME_DEFAULT,
            formatDate: (date, format, locale) => {
              return datetimeFormatter(date, format);
            },
          }}
          {...props}
        />
      );
    case INPUT_TYPE_IMAGE:
    case INPUT_TYPE_VIDEO:
    case INPUT_TYPE_AUDIO:
    case INPUT_TYPE_FILE:
      return (
        <FileUploaderSection
          file_id={value || ''}
          onChange={(v) => setFieldValue(name, v)}
          input_label={label}
          accept_file_types={getAcceptFileTypes(type)}
          image_croppable={[INPUT_TYPE_IMAGE].includes(type)}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_RICH_TEXT:
      return (
        <RichTextEditor
          label={label}
          value={value || ''}
          onChange={handleChange(name)}
          readOnly={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_COLOR:
      return (
        <TextInput
          name={name}
          label={label}
          value={value}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          type={type}
          disabled={disabled}
          inputProps={{
            type: 'color',
          }}
          {...props}
        />
      );
    default:
      return (
        <TextInput
          name={name}
          label={label}
          value={value}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          type={type}
          disabled={disabled}
          inputProps={{
            step: 'any',
          }}
          {...(type === INPUT_TYPE_TEXTAREA ? {
            multiline: true,
            minRows: 3,
            maxRows: 12,
          } : {})}
          {...props}
        />
      );
  }
};

InputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  name: '',
  value: null,
  label: 'Label',
  type: INPUT_TYPE_TEXT,
  options: [],
  handleChange: () => { },
  setFieldValue: () => { },
  handleBlur: () => { },
  disabled: false,
};

export default InputField;

import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { getApps } from 'api/apps';
import { useProject } from 'contexts/project';
import { Button } from '@mui/material';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { logout } from 'api/auth';
import BigBreadcrumb from 'components/BigBreadcrumb';

const ChooseProjectSection = ({ ...props }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const { setProject } = useProject();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [sortBy, setSortBy] = useState('display_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchApps = useCallback(() => {
    const request_params = {
      $expand: 'pages/sections',
      $orderBy: `${sortBy} ${sortDirection}`,
    };
    return getApps(request_params)
      .then((res) => {
        return res.data;
      });
  }, [sortBy, sortDirection]);

  useEffect(() => {
    fetchApps()
      .then((data) => {
        setProjects(data);
      });
  }, [fetchApps]);

  const saveToLocalStorage = useCallback((selectedId) => {
    localStorage.setItem('selectedProject', selectedId);
  }, []);

  const handleAppSelect = useCallback((app_id) => () => {
    const selected = projects.find((p) => p.app_id === app_id);
    saveToLocalStorage(app_id);
    setProject(selected);
    setSelectedProject(app_id);
  }, [projects, setProject, saveToLocalStorage]);

  const columns = useMemo(() => [
    {
      label: 'Project Name',
      field: 'display_name',
      align: 'left',
      sortable: true,
    },
    {
      label: 'Updated date',
      field: 'lastmoddate',
      formatter: (value) => {
        return datetimeFormatter(value, 'MMM DD, yyyy, hh:mm:ss a');
      },
      sortable: true,
    },
    {
      label: '',
      field: 'choose_button',
    },
  ], []);

  const onClickSort = useCallback((colName) => {
    const tableCols = columns.find((col) => col.field === colName);
    if (tableCols?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [columns]);

  const rows = useMemo(() => {
    return projects.map((p) => ({
      ...p,
      choose_button: (
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={handleAppSelect(p.app_id)}
        >
          <MKTypography
            color="#fff"
            fontSize="12px"
            fontWeight="bold"
          >
            Choose
          </MKTypography>
        </Button>
      ),
    }));
  }, [handleAppSelect, projects]);

  const onPressAdd = useCallback(() => {
    navigate('/createapp?solution_id=afa0710d-1a51-4571-a97c-339f95fbb9b8');
  }, [navigate]);

  const onPressDelete = useCallback(() => {
    console.log('==Todo== Implement project delete');
    window.alert('==Todo== Implement project delete');
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <MKBox {...props}>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <MKBox
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <MKTypography
            variant="b1"
            color="black"
            fontSize="16px"
            fontWeight="bold"
          >
            {`Welcome, ${auth?.user?.display_name}`}
          </MKTypography>
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => { logout(); navigate('/login'); }}
          >
            Logout
          </Button>
        </MKBox>
        <MKTypography
          variant="b1"
          color="black"
          fontSize="16px"
          fontWeight="bold"
          mb={2}
        >
          {`Client time: ${datetimeFormatter(new Date(), 'MMMM DD, yyyy | hh:mm:ss a | dddd')}`}
        </MKTypography>
      </MKBox>

      <BigBreadcrumb
        items={[
          { label: 'Choose Project', to: '/choose' },
        ]}
      />
      <DataTable
        idField="app_id"
        columns={columns}
        data={rows}
        onPressAdd={onPressAdd}
        onPressDelete={onPressDelete}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        selectable
      />
    </MKBox>
  );
};

ChooseProjectSection.propTypes = {
  // props
};

export default ChooseProjectSection;

import React, { useState, useEffect, useCallback } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getSurveyAnswerSubmission } from 'api/surveys';
import MKTypography from 'components/MaterialKit/MKTypography';
import { datetimeFormatter } from 'utils/datetime';
import { Button } from '@mui/material';

const SurveyResponses = () => {
  const [responses, setResponses] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const surveyId = searchParams.get('surveyId');
  const [viewedResponse, setViewedResponse] = useState(null);

  const fetchResponses = useCallback(() => {
    if (!surveyId) {
      return;
    }
    return getSurveyAnswerSubmission({
      $expand: 'survey_question_answers/survey_question,survey_question_answers/survey_question_choice',
    })
      .then(({ data }) => {
        const filtered = data.map((response) => {
          if (response.survey_question_answers.length === 0) {
            return null;
          }
          const surveyIdentifier = response.survey_question_answers[0].survey_question.survey;
          if (surveyIdentifier !== surveyId) {
            return null;
          }
          return {
            ...response,
          };
        }).filter(Boolean);

        setResponses(filtered);
      });
  }, [surveyId]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  return (
    <MKBox mt={2} display="flex" flexDirection="column">
      <MKBox mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
        <MKTypography variant="h6" color="black">{`Survey Responses (${responses?.length})`}</MKTypography>
        {
          responses.map((response) => {
            return (
              <MKBox key={response.id} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px', cursor: 'pointer', backgroundColor: viewedResponse?.survey_question_answer_submission_id === response.survey_question_answer_submission_id ? 'lightblue' : 'transparent' }} onClick={() => setViewedResponse(response)}>
                <MKTypography variant="h6" color="black">{`${response.user}: ${datetimeFormatter(response.createddate)}`}</MKTypography>
              </MKBox>
            );
          })
        }
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(`/reports/surveys/survey/fillout?surveyId=${surveyId}`)}
          sx={{ mt: 1 }}
        >
          <MKTypography variant="h6" color="white">Fill</MKTypography>
        </Button>
      </MKBox>
      { viewedResponse && (
      <MKBox mt={3} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
        <MKTypography variant="h6" color="black">Survey response details</MKTypography>
        {
          viewedResponse.survey_question_answers
            .sort((a, b) => a.survey_question.sequence - b.survey_question.sequence)
            .map((answer) => {
              return (
                <MKBox key={answer.id} mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
                  <MKTypography variant="h6" color="black">{`${answer.survey_question.sequence}. ${answer.survey_question.question_text}`}</MKTypography>
                  <MKTypography variant="h6" color="black">{`Response: ${answer.survey_question_choice ? answer.survey_question_choice.label : answer.survey_question_answer_text}`}</MKTypography>
                </MKBox>
              );
            })
        }
      </MKBox>
      ) }
    </MKBox>
  );
};

export default SurveyResponses;

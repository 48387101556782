import React from 'react';
import MKBox from 'components/MaterialKit/MKBox';

const SurveySettings = () => {
  return (
    <MKBox mt={2} display="flex" flexDirection="column">
      <MKBox mt={1} p={1} sx={{ border: '1px solid gray', borderRadius: '8px' }}>
        Survey Settings
      </MKBox>
    </MKBox>
  );
};

export default SurveySettings;

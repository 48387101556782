import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createDynamicTableRow, createSection } from 'api/sections';
import PropTypes from 'prop-types';
import ElementAddForm from './elementAddForm';

const ElementsMenuAddOne = ({ pageId, sectionDefinition, onCancel }) => {
  const navigate = useNavigate();

  const onClickCancel = useCallback(() => {
    if (onCancel) {
      return onCancel();
    }
  }, [onCancel]);

  const onSubmitSectionToApi = useCallback((values, { setFieldError }) => {
    const selectedSectionDefinition = sectionDefinition;
    const selectedCollectionDefinitionId = selectedSectionDefinition?.collection_definition?.collection_definition_id;

    if (!selectedCollectionDefinitionId) {
      return Promise.resolve();
    }
    const updateBody = {
      section_definition: values.section_definition,
      sequence: values.sequence,
      is_viewport: values.is_viewport,
    };
    const createSectionData = () => createDynamicTableRow(selectedCollectionDefinitionId, {})
      .then(({ data }) => {
        const createBody = {
          ...updateBody,
          page: pageId,
          dyn_t: data?.id,
        };
        return createSection(createBody);
      });
    return (createSectionData())
      .then((response) => {
        if (response.status === 201) {
          window.location.reload();
        }
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [pageId, sectionDefinition]);

  return (
    <MKBox flex={1}>
      <ElementAddForm
        sectionDefinition={sectionDefinition}
        onSubmit={onSubmitSectionToApi}
        onCancel={onClickCancel}
      />
    </MKBox>
  );
};

ElementsMenuAddOne.propTypes = {
  pageId: PropTypes.string,
  sectionDefinition: PropTypes.object,
  onCancel: PropTypes.func,
};

export default ElementsMenuAddOne;

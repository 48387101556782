import MKBox from 'components/MaterialKit/MKBox';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { getSolutions } from 'api/solutions';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NextPlanRoundedIcon from '@mui/icons-material/NextPlanRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { IconButton, Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { createPage, deletePage } from 'api/pages';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKInput from 'components/MaterialKit/MKInput';
import { Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

// eslint-disable-next-line import/no-cycle
import { createDynamicTableRow, getDynamicTableRow, createSection, getSections } from 'api/sections';

const WebpageDashboardSection = ({ projectId, ...props }) => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copiedPage, setCopiedPage] = useState(null);
  const [toDelete, setToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [localPages, setLocalPages] = useState([]);

  const handlePageNavigation = (application, root, page, type) => {
    let url = `${application.base_url}`;
    if (root) {
      if (type === 'setting') {
        url = `/builder/${application.app_id}/build`;
        navigate(url);
        return;
      }
      url += '/';
    } else if (type === 'setting') {
      url = `/builder/${application.app_id}/build?pageId=${page}`;
      navigate(url);
      return;
    } else {
      const pagePath = application.pages.find((p) => p.page_id === page).path;
      url += `${pagePath}`;
    }
    const redirectUrl = `${window.location.origin}${url}`;
    window.open(redirectUrl, '_blank');
  };

  const fetchSolutionsFromApi = useCallback(() => {
    return getSolutions({ $expand: 'apps/pages' })
      .then(({ data }) => {
        setLoading(false);
        setTreeData(data);
        if (data.length > 0) {
          const appIds = data[0].apps.map((app) => app.app_id);
          const pagesIds = data[0].apps.map((app) => {
            return app.pages.map((page) => page.path);
          });
          setExpanded([data[0].solution_id, ...appIds, ...pagesIds.flat()]);
        }
      })
      .catch((err) => {
        setLoading(true);
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    fetchSolutionsFromApi();
  }, [fetchSolutionsFromApi]);

  const NodeIds = useMemo(() => {
    if (!treeData || !Array.isArray(treeData)) return [];
    const appIds = [];
    treeData.forEach((solution) => {
      if (solution.apps && Array.isArray(solution.apps)) {
        appIds.push(solution.solution_id);
        solution.apps.forEach((app) => {
          appIds.push(app.app_id);
          app.pages.forEach((page) => {
            appIds.push(page.path);
          });
        });
      }
    });
    return appIds;
  }, [treeData]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  function transformDataToTree(pagesDataArray) {
    const paths = pagesDataArray.map((page) => page.path);
    const tree = {};
    paths.forEach((path) => {
      const parts = path.split('/').filter(Boolean);
      let current = tree;
      parts.forEach((part) => {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      });
    });

    return tree;
  }

  const handlePageCopy = (app, pageId) => {
    const page = app.pages.find((p) => p.page_id === pageId);
    const newPage = { ...page };
    delete newPage.page_id;
    setCopiedPage(newPage);
  };

  const getDynamicTableRowsForPageSections = useCallback((sections) => {
    const promises = sections.map((section) => {
      return getDynamicTableRow(section.section_definition.collection_definition, section.dyn_t);
    });
    return Promise.all(promises);
  }, []);

  const handlePageDelete = (pageId) => {
    setLoading(true);
    return deletePage(pageId)
      .then(() => {
        return fetchSolutionsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setDeleteDialogOpen(false);
        setLoading(false);
      });
  };

  const handleOpen = (app, pageId) => {
    handlePageCopy(app, pageId);
    setOpen(true);
  };

  const handleDeleteOpen = (page) => {
    setToDelete(page);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setToDelete(null);
  };

  const handleClose = () => {
    setOpen(false);
    setCopiedPage(null);
  };

  const handlePagePaste = useCallback((page) => {
    setLoading(true);
    let currentSections = [];
    let pageid = null;
    createPage(page)
      .then(({ data }) => {
        pageid = data.page_id;
        return getSections({ 'section_id[in]': page.sections.join(','), '$expand': 'section_definition' });
      })
      .then(({ data }) => {
        currentSections = data;
        return getDynamicTableRowsForPageSections(data);
      })
      .then((rows) => {
        const top = currentSections.map((section) => {
          const row = rows.find((r) => r.data.id === section.dyn_t)?.data;
          row.collection_definition = section.section_definition.collection_definition;
          return row;
        });
        const promises = top.map((row) => {
          const body = {
            json_short_data: row.json_short_data,
          };
          return createDynamicTableRow(row.collection_definition, body);
        });
        return Promise.all(promises);
      })
      .then((newRows) => {
        const newRowsWithSection = newRows.map((row, index) => {
          return {
            ...row?.data,
            section: currentSections[index],
          };
        });
        return newRowsWithSection;
      })
      .then((newRowsWithSection) => {
        const newSections = newRowsWithSection.map((row) => {
          const body = {
            section_definition: row.section.section_definition.section_definition_id,
            sequence: row.section.sequence,
            dyn_t: row.id,
            page: pageid || null,
            is_viewport: row.section.is_viewport,
          };
          return createSection(body);
        });
        return Promise.all(newSections);
      })
      .finally(() => {
        handleClose();
        return fetchSolutionsFromApi();
      });
  }, [fetchSolutionsFromApi, getDynamicTableRowsForPageSections]);

  const onPressAddPage = () => {
    navigate(`/builder/${projectId}/build`);
  };

  const renderAppPages = (node, path = '', appId, pages, app) => {
    if (appId !== projectId) return null;
    return Object.keys(node).map((nodeName) => {
      const newPath = `${path}/${nodeName}`;
      const isLeaf = Object.keys(node[nodeName]).length === 0;
      const page = pages.find((p) => p.path === newPath);
      return (
        <div key={newPath} style={{ display: 'flex' }}>
          <TreeItem nodeId={newPath} label={nodeName} sx={{ alignItems: 'center', flexGrow: 1, m: '4px 4px 4px 4px' }}>
            {!isLeaf && renderAppPages(node[nodeName], newPath, appId, pages, app)}
          </TreeItem>
          <MKBox display="flex" justifyContent="center" alignItems="start">
            <Tooltip title="Navigate to Page" arrow>
              <NextPlanRoundedIcon color="secondary" fontSize="medium" onClick={() => handlePageNavigation(app, false, page?.page_id, 'navigate')} />
            </Tooltip>
            <Tooltip title="Open page in WebBuilder" arrow>
              <SettingsApplicationsIcon color="primary" fontSize="medium" onClick={() => handlePageNavigation(app, false, page?.page_id, 'setting')} />
            </Tooltip>
            <Tooltip title="Copy page" arrow>
              <ContentCopyRoundedIcon color="primary" fontSize="medium" onClick={() => handleOpen(app, page?.page_id)} />
            </Tooltip>
            <Tooltip title="Delete page" arrow>
              <DeleteRoundedIcon color="primary" fontSize="medium" onClick={() => handleDeleteOpen(page)} />
            </Tooltip>
          </MKBox>
        </div>
      );
    });
  };

  const preparePagesAndRender = (app) => {
    const pagesDataArray = app?.pages.map((page) => ({
      path: page.path,
      id: page.id,
    }));
    const pagesTree = transformDataToTree(pagesDataArray);
    return renderAppPages(pagesTree, app.app_url, app.app_id, app.pages, app);
  };

  const onExpandAllClicked = () => {
    setExpanded(NodeIds);
  };

  const onCollapseAllClicked = () => {
    setExpanded([]);
  };

  const renderTreeItems = (nodes) => {
    return nodes.map((solution) => (
      <TreeItem key={solution.solution_id} nodeId={solution.solution_id} label={solution.display_name}>
        {solution?.apps.map((app) => {
          if (app.app_id !== projectId) return null;
          return (
            <MKBox display="flex" justifyContent="center" alignItems="start">
              <TreeItem key={app.app_id} nodeId={app.app_id} label={<MKTypography>{app?.display_name}</MKTypography>} sx={{ minHeight: '100px', flexGrow: 1, p: '4px 4px 4px 4px' }}>
                {app?.pages && preparePagesAndRender(app)}
              </TreeItem>
              <Stack direction="row">
                <Tooltip title="Navigate to App" arrow>
                  <NextPlanRoundedIcon color="secondary" fontSize="medium" onClick={() => handlePageNavigation(app, true, null, 'navigate')} />
                </Tooltip>
                <Tooltip title="Open page in WebBuilder" arrow>
                  <SettingsApplicationsIcon color="primary" fontSize="medium" onClick={() => handlePageNavigation(app, true, null, 'setting')} />
                </Tooltip>
              </Stack>
            </MKBox>
          );
        })}
      </TreeItem>
    ));
  };

  return (
    <MKBox {...props}>
      {loading
        ? (
          <MKBox display="flex" justifyContent="center" sx={{ backgroundColor: '#F1F2F5', borderRadius: '8px', mt: 2 }}>
            <CircularProgress color="secondary" thickness={3} />
          </MKBox>
        ) : (
          <MKBox display="flex" flexDirection="column" justifyContent="center" sx={{ backgroundColor: '#F1F2F5', borderRadius: '8px', p: 1 }}>
            <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
              <Button variant="contained" color="primary" onClick={onPressAddPage}>
                <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                  Add Page
                </MKTypography>
              </Button>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Button variant="contained" color="primary" onClick={onExpandAllClicked}>
                  <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                    Expand All
                  </MKTypography>
                </Button>
                <Button variant="contained" color="primary" onClick={onCollapseAllClicked}>
                  <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                    Collapse All
                  </MKTypography>
                </Button>
              </Stack>
            </Stack>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeToggle={handleToggle}
              expanded={expanded}
              sx={{ flexGrow: 1, borderRadius: '8px', border: '1px solid black', mt: 2 }}
            >
              {treeData && renderTreeItems(treeData)}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                  <MKTypography variant="h6" color="black" fontSize="16px">{`Copied Page: ${copiedPage?.path}`}</MKTypography>
                </DialogTitle>
                <DialogContent>
                  {copiedPage && (
                  <Formik
                    initialValues={{ newPagePath: `${copiedPage?.path}_copy` || '' }}
                    onSubmit={(values, { setFieldError }) => {
                      if (!values.newPagePath) return;
                      if (!values.newPagePath.startsWith('/')) { setFieldError('newPagePath', 'Path must start with /'); }
                      const newPage = { ...copiedPage };
                      newPage.path = values.newPagePath;
                      handlePagePaste(newPage);
                    }}
                  >
                    {({ values, handleChange, handleSubmit, setFieldError }) => (
                      <MKBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1}>
                        <MKInput
                          type="text"
                          label="New Page Path"
                          value={values.newPagePath || ''}
                          onChange={handleChange('newPagePath')}
                          fullWidth
                        />
                        <MKBox
                          display="flex"
                          width="100%"
                        >
                          <Stack direction="row" justifyContent="space-between" spacing={1} mt={1.5} width="100%">
                            <Button variant="contained" color="secondary" onClick={handleClose}>
                              <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                                Cancel
                              </MKTypography>
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                              <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                                Create Copy
                              </MKTypography>
                            </Button>
                          </Stack>

                        </MKBox>
                      </MKBox>
                    )}
                  </Formik>
                  )}
                </DialogContent>
              </Dialog>
              <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
                <DialogTitle>
                  <MKTypography variant="h6" color="black" fontSize="16px">{`Delete page? ${toDelete?.path}`}</MKTypography>
                </DialogTitle>
                <DialogContent>
                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={handleDeleteClose}>
                      <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                        Cancel
                      </MKTypography>
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handlePageDelete(toDelete?.page_id)}>
                      <MKTypography variant="body1" color="white" fontSize="12px" fontWeight="bold" align="center">
                        Yes
                      </MKTypography>
                    </Button>
                  </Stack>
                </DialogContent>
              </Dialog>
            </TreeView>
          </MKBox>
        )}
      {/* <Stack direction="row" gap={1}>
        <Button
          component="label"
          variant="contained"
          color="dark"
          onClick={handleExpandClick}
          startIcon={expanded.length !== 0 ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
        >
          {expanded.length !== 0 ? 'Collapse All' : 'Expand All' }
        </Button>
      </Stack> */}
    </MKBox>
  );
};

WebpageDashboardSection.defaultProps = {
  projectId: '',
};

WebpageDashboardSection.propTypes = {
  projectId: PropTypes.string,
};

export default WebpageDashboardSection;

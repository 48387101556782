import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getSectionDefinitions } from 'api/sections';
import { Divider, Icon } from '@mui/material';
import Grid from '@mui/material/Grid';
import MKTypography from 'components/MaterialKit/MKTypography';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import { useElement } from 'contexts/element';
import ElementsMenuAddOne from './elementsAdd';
import ElementEditProps from './elementEditProps';

const ids = {
  '17faadf5-bd10-4c54-b379-2552a84518e6': <VideoCameraBackOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} fontSize="4rem" />,
  '744f9aa7-d1f5-4b9f-af2c-cfdfc52b76db': <ImageOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} fontSize="4rem" />,
  '70ddadce-03fb-48ab-9108-e701b3932dfa': <CheckBoxOutlineBlankOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} />,
  'c51ec0d3-f536-4cbb-8294-4a8ac4417728': <GridViewIcon sx={{ width: '1.5em', height: '1.5em' }} />,
  'a1a2685b-9e0b-4a43-bb46-2f94efda8925': <SmartButtonOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} />,
  '49b831a0-b912-4720-aeed-dd7a967be539': <AbcOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} />,
  '98d9f57a-8315-4761-9da8-f413d99d2dbb': <AbcOutlinedIcon sx={{ width: '1.5em', height: '1.5em' }} />,
};

const ElementsMenu = ({ pageId }) => {
  const { element } = useElement();
  const [sectionDefinitions, setSectionDefinitions] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const copiedSection = useMemo(() => {
    return JSON.parse(window.localStorage.getItem('copiedSectionData'));
  }, []);

  const fetchSectionDefinitionsFromApi = useCallback(() => {
    setLoading(true);
    const sectionDefinitionParams = {
      $expand: 'collection_definition',
    };
    if (!loadMore) {
      sectionDefinitionParams['collection_definition[in]'] = Object.keys(ids).join(',');
    }
    return getSectionDefinitions(sectionDefinitionParams)
      .then(({ data }) => {
        setSectionDefinitions(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadMore]);

  useEffect(() => {
    fetchSectionDefinitionsFromApi();
  }, [fetchSectionDefinitionsFromApi]);

  const onLoadClicked = useCallback(() => {
    setLoadMore(!loadMore);
  }, [loadMore]);

  const favors = useMemo(() => {
    return sectionDefinitions
      .filter((sectionDefinition) => Object.keys(ids).includes(sectionDefinition.collection_definition.collection_definition_id))
      .sort((a, b) => a.collection_definition.name.localeCompare(b.collection_definition.name));
  }, [sectionDefinitions]);

  const theRest = useMemo(() => {
    return sectionDefinitions
      .filter((sectionDefinition) => !Object.keys(ids).includes(sectionDefinition.collection_definition.collection_definition_id))
      .sort((a, b) => a.collection_definition.name.localeCompare(b.collection_definition.name));
  }, [sectionDefinitions]);

  if (!selectedSection && !element) {
    return (
      <MKBox display="flex" flexDirection="column" sx={{ width: '100%', height: '100%' }}>
        <MKBox sx={{ mt: 1 }}>
          <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
            Individual Elements
          </MKTypography>
          <Grid container justifyContent="space-between" alignItems="flex-start" gap={0} spacing={1} sx={{ mt: 1 }}>
            {favors.map((sectionDefinition) => {
              return (
                <Grid item xs={6} key={sectionDefinition.section_definition_id}>
                  <MKBox
                    key={sectionDefinition.section_definition_id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      minHeight: '120px',
                      border: '1px solid black',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => { setSelectedSection(sectionDefinition); }}
                  >
                    <MKBox display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                      {ids[sectionDefinition.collection_definition.collection_definition_id] && (
                        ids[sectionDefinition.collection_definition.collection_definition_id]
                      )}
                      <MKTypography
                        variant="h6"
                        color="black"
                        fontSize="0.75rem"
                        fontWeight="bold"
                        align="center"
                        px={1}
                        sx={{
                          'whiteSpace': 'nowrap',
                          'overflow': 'hidden',
                          'textOverflow': 'ellipsis',
                          '&:hover': {
                            overflow: 'hidden',
                            textOverflow: 'clip',
                            animation: 'marquee 3s linear infinite',
                          },
                          '@keyframes marquee': {
                            '0%': { textIndent: '0%' },
                            '100%': { textIndent: '-100%' },
                          },
                        }}
                      >
                        {sectionDefinition?.collection_definition?.name}
                      </MKTypography>
                    </MKBox>

                  </MKBox>
                </Grid>
              );
            })}
          </Grid>
        </MKBox>
        <Divider />
        <MKBox onClick={() => onLoadClicked()} sx={{ cursor: 'pointer' }}>
          <MKBox display="flex" justifyContent="space-between" alignItems="center">
            <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
              {!loadMore && !loading ? 'Load More Elements' : 'Show Less'}
            </MKTypography>
            <ExpandLessIcon sx={{ display: loadMore ? 'block' : 'none' }} />
            <ExpandMoreIcon sx={{ display: !loadMore ? 'block' : 'none' }} />
          </MKBox>
          {loading ? (
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size="3rem" color="secondary" thickness={4} />
            </MKBox>
          ) : (
            <Grid container justifyContent="space-between" alignItems="flex-start" gap={0} spacing={1} sx={{ mt: 1 }}>
              {theRest.map((sectionDefinition) => {
                return (
                  <Grid item xs={6} key={sectionDefinition.section_definition_id}>
                    <MKBox
                      key={sectionDefinition.section_definition_id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        minHeight: '120px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => { setSelectedSection(sectionDefinition); }}
                    >
                      <MKTypography
                        variant="h6"
                        color="black"
                        fontSize="0.75rem"
                        fontWeight="bold"
                        align="center"
                        px={1}
                        sx={{
                          'whiteSpace': 'nowrap',
                          'overflow': 'hidden',
                          'textOverflow': 'ellipsis',
                          '&:hover': {
                            overflow: 'hidden',
                            textOverflow: 'clip',
                            animation: 'marquee 3s linear infinite',
                          },
                          '@keyframes marquee': {
                            '0%': { textIndent: '0%' },
                            '100%': { textIndent: '-100%' },
                          },
                        }}
                      >
                        {sectionDefinition?.collection_definition?.name}
                      </MKTypography>
                    </MKBox>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </MKBox>

      </MKBox>
    );
  }
  if (selectedSection) {
    return (
      <ElementsMenuAddOne pageId={pageId} sectionDefinition={selectedSection} onCancel={() => { setSelectedSection(null); }} />
    );
  }
  return (
    <ElementEditProps sectionId={element} />
  );
};

ElementsMenu.propTypes = {
  pageId: PropTypes.string,
};

export default ElementsMenu;

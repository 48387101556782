import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getDynamicTableRows, setUserObjectCache } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicData } from 'utils/sections';

const DatastoreMenu = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const isOpen = useMemo(() => open === 'datastore', [open]);
  const [links, setLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [collectionDefinition, setCollectionDefinition] = useState({});

  const fetchCollectionDefinition = useCallback(() => {
    return getCollectionDefinition('e901696a-a7cb-4c2b-bdb0-fae1bfa2f855', { $expand: 'attributes' })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }, []);

  const fetchLinksFromApi = useCallback(() => {
    return getDynamicTableRows('e901696a-a7cb-4c2b-bdb0-fae1bfa2f855', {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }, []);

  useEffect(() => {
    Promise.all([fetchLinksFromApi(), fetchCollectionDefinition()])
      .then(([linksData, collectionDefinitionData]) => {
        setLinks(linksData);
        setCollectionDefinition(collectionDefinitionData);
      });
  }, [fetchCollectionDefinition, fetchLinksFromApi]);

  const handleOpen = () => {
    if (open === 'datastore') { setOpen(null); }
    if (open !== 'datastore') { setOpen('datastore'); }
    navigate('/solutions/cms');
  };

  const onSaveToObjectCache = useCallback((selectedId) => {
    const selectedRow = links.find(({ id }) => id === selectedId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    return Promise.all([
      setUserObjectCache(collectionDefinition.collection_definition_id, selectedId),
      (dynamicData?.redirect_collection_definition_id && dynamicData?.redirect_object_id) ? (
        setUserObjectCache(dynamicData.redirect_collection_definition_id, dynamicData.redirect_object_id)
      ) : null,
    ])
      .then(([d, a]) => {
        return d;
      });
  }, [collectionDefinition?.attributes, collectionDefinition.collection_definition_id, links]);

  const handleRedirect = (link) => {
    onSaveToObjectCache(link.id)
      .then(() => {
        const linkUrl = `/solutions/cms/${link?.name?.replace(/\s/g, '-').toLowerCase()}`;
        setSelectedLink(link);
        navigate(linkUrl);
      });
  };

  const linkNavigations = useMemo(() => links.map((link) => {
    const linkBody = getDynamicData(link, collectionDefinition?.attributes);
    return {
      id: link.id,
      ...linkBody,
    };
  }), [collectionDefinition?.attributes, links]);

  return (
    <MKBox>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => handleOpen()}
        p={2}
        sx={{ 'cursor': 'pointer',
          '&:hover': { backgroundColor: 'lightgray' },
          'backgroundColor': isOpen ? 'lightgray' : 'white',
        }}
      >
        <MKTypography variant="h6" color="black" fontSize="16px" fontWeight="bold">
          Data Store
        </MKTypography>
        { isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
      </MKBox>
      { isOpen && (
        <MKBox>
          {linkNavigations.map((link) => (
            <MKBox
              pl={4}
              py={1}
              key={link?.id}
              onClick={() => handleRedirect(link)}
              sx={{
                'cursor': 'pointer',
                '&:hover': { backgroundColor: 'lightgray' },
                'backgroundColor': selectedLink?.id === link?.id ? 'lightgray' : 'white',
              }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px">
                {link?.name}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>
      )}
    </MKBox>

  );
};

DatastoreMenu.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.func,
};

DatastoreMenu.defaultProps = {
  open: '',
  setOpen: () => {},
};

export default DatastoreMenu;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import Container from '@mui/material/Container';
import BigBreadcrumb from 'components/BigBreadcrumb';
import { useProject } from 'contexts/project';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getSurveys } from 'api/surveys';
import DataTable from 'components/DataTable';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

const SurveysSection = () => {
  const { project } = useProject();
  const appId = project?.app_id;
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);

  const fetchSurveyFromApi = useCallback(() => {
    if (!appId) {
      return Promise.resolve();
    }
    return getSurveys({ '$expand': 'survey_questions', 'app[eq]': appId })
      .then(({ data }) => { setSurveys(data); });
  }, [appId]);

  const onPressAddSurvey = useCallback(() => {
    navigate('/reports/surveys/form');
  }, [navigate]);

  const onPressEditSurvey = useCallback((surveyId) => {
    navigate(`/reports/surveys/form?surveyId=${surveyId}`);
  }, [navigate]);

  useEffect(() => {
    fetchSurveyFromApi();
  }, [fetchSurveyFromApi]);

  const columns = useMemo(() => [
    {
      field: 'survey_id',
      label: 'ID',
      sortable: true,
    },
    {
      field: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      field: 'questions',
      label: 'Questions',
    },
    {
      field: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      field: 'preview',
      sortable: false,
      renderContent: (content) => {
        return (
          <MKBox display="flex" flexDirection="row">
            <Button
              target="_self"
              variant="gradient"
              color="info"
              size="small"
              fullWidth
              fontSize={12}
              onClick={() => { navigate(`/reports/surveys/survey?surveyId=${content.survey_id}`); }}
            >
              View
            </Button>
          </MKBox>
        );
      },
    },
  ], [navigate]);

  const rows = useMemo(() => surveys.map((item) => ({
    ...item,
    questions: item.survey_questions?.length,
    preview: item,
  })), [surveys]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Reports', to: '/reports/overview' },
            { label: 'Surveys', to: '/reports/surveys' },
          ]}
          sx={{ mb: 2 }}
        />
        <MKBox>
          {surveys.length ? (
            <DataTable
              columns={columns}
              data={rows}
              onPressAdd={onPressAddSurvey}
              idField="survey_id"
              selectable
              onPressEdit={onPressEditSurvey}
            />
          ) : (
            <MKTypography variant="h5">
              <Button onClick={onPressAddSurvey}>Add new for this project</Button>
            </MKTypography>
          )}
        </MKBox>
      </Container>
    </MKBox>
  );
};

export default SurveysSection;

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoginForm from 'components/LoginForm';
import MKBox from 'components/MaterialKit/MKBox';
import { useAuth } from 'contexts/auth';
import { getStoredAuth } from 'api';
import { useApp } from 'contexts/app';
import { login } from 'api/auth';

const loginFormInitialValues = {
  username: '',
  password: '',
};

const LoginSection = ({
  has_remember_me_option,
  app_id,
  enable_show_password,
  button_color,
  button_title,
  username_placeholder,
  password_placeholder,
  show_sign_up,
  show_forgot_password,
  text_color,
  navigate_to,
  forgot_password_url,
  register_url,
  username_label,
  password_label,
  ...props }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { app } = useApp();
  const appId = app_id || app?.app_id;

  const onLoginToApi = useCallback((values, { setFieldError }) => {
    return login(values.username, values.password)
      .then(({ data: user }) => {
        if (user?.app !== appId) {
          setFieldError('form', 'Invalid app');
          return;
        }
        const newAuth = { ...getStoredAuth(), user };
        setAuth(newAuth);
        if (searchParams.get('redirect')) {
          navigate(searchParams.get('redirect'));
        } else {
          navigate(navigate_to || '/');
        }
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [appId, setAuth, searchParams, navigate, navigate_to]);

  return (
    <Container>
      <MKBox py="2rem">
        <LoginForm
          onLogin={onLoginToApi}
          initialValues={loginFormInitialValues}
          enableShowPassword={enable_show_password}
          enableRememberMeOption={has_remember_me_option}
          buttonColor={button_color}
          buttonTitle={button_title}
          usernamePlaceholder={username_placeholder}
          passwordPlaceholder={password_placeholder}
          showSignUp={show_sign_up}
          showForgotPassword={show_forgot_password}
          textColor={text_color}
          forgotPasswordUrl={forgot_password_url}
          registerUrl={register_url}
          passwordLabel={password_label}
          usernameLabel={username_label}
          {...props}
        />
      </MKBox>
    </Container>
  );
};

LoginSection.propTypes = {
  enable_show_password: PropTypes.bool,
  has_remember_me_option: PropTypes.bool,
  show_sign_up: PropTypes.bool,
  show_forgot_password: PropTypes.bool,
  button_title: PropTypes.string,
  button_color: PropTypes.string,
  username_placeholder: PropTypes.string,
  password_placeholder: PropTypes.string,
  username_label: PropTypes.string,
  password_label: PropTypes.string,
  text_color: PropTypes.string,
  navigate_to: PropTypes.string,
  forgot_password_url: PropTypes.string,
  register_url: PropTypes.string,
  app_id: PropTypes.string,
};

LoginSection.defaultProps = {
  enable_show_password: false,
  has_remember_me_option: false,
  show_sign_up: true,
  show_forgot_password: false,
  navigate_to: null,
  forgot_password_url: null,
  register_url: null,
};

export default LoginSection;

import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { getApp, updateApp } from 'api/apps';
import { useAuth } from 'contexts/auth';
import BigBreadcrumb from 'components/BigBreadcrumb';
import AppForm from './appForm';
import PageSection from './pageSection';
import MenuSection from './menuSection';

const AppSummarySection = () => {
  const [app, setApp] = useState(null);
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('appId');
  const { setAuth } = useAuth();

  const fetchAppFromApi = useCallback(() => {
    if (!appId) {
      return Promise.resolve();
    }
    const appParams = {
      $expand: 'pages',
    };
    return getApp(appId, appParams)
      .then(({ data }) => {
        setApp(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [appId, setAuth]);

  const updateAppToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      display_name: values.display_name,
      base_url: values.base_url,
      major_version: values.major_version,
      minor_version: values.minor_version,
      type: values.type,
    };
    return updateApp(appId, updateBody)
      .then(({ data }) => {
        setApp(data);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [appId]);

  useEffect(() => {
    fetchAppFromApi();
  }, [fetchAppFromApi]);

  if (!appId) return null;

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Apps', link: '/apps' },
            { label: app?.display_name },
          ]}
          sx={{ mb: 2 }}
        />
        <AppForm
          app={app}
          onSave={updateAppToApi}
        />
        <PageSection app={app} />
        <MenuSection app={app} />
      </Container>
    </MKBox>
  );
};

export default AppSummarySection;

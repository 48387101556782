import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import FetchingTable from 'sections/FetchingTable';
import { useProject } from 'contexts/project';
import { useAuth } from 'contexts/auth';
import { usersTableColumns } from './tablecolumns';

const UsersTableSection = ({
  background_color,
  page_options,
  addable,
  editable,
  deletable,
  add_button_label,
  edit_button_label,
  delete_button_label,
  ...props
}) => {
  const { project } = useProject();
  const { auth } = useAuth();
  const appIdParams = useMemo(() => {
    // return project?.app_id ? { app: project.app_id } : {};
    return auth?.user?.app ? { app: auth?.user?.app } : {};
  }, [auth?.user?.app]);

  return (
    <MKBox {...props}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">Users</MKTypography>
      </MKBox>
      <FetchingTable
        table_name="users"
        id_field="username"
        page_options={page_options}
        extra_params={appIdParams}
        columns={usersTableColumns}
        add_data_path="/users/add"
        edit_data_path="/users/edit"
        addable={addable}
        editable={editable}
        deletable={deletable}
        add_button_label={add_button_label}
        edit_button_label={edit_button_label}
        delete_button_label={delete_button_label}
      />
    </MKBox>
  );
};

UsersTableSection.propTypes = {
  background_color: PropTypes.string,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
};

export default UsersTableSection;

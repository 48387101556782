import MKBox from 'components/MaterialKit/MKBox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProject } from 'contexts/project';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import MKTypography from 'components/MaterialKit/MKTypography';

const ChooseProjectMenu = () => {
  const navigate = useNavigate();
  const { project, setProject } = useProject();
  return (
    <Button
      fullWidth
      onClick={() => navigate('/choose')}
      startIcon={project ? <ArrowLeftIcon color="black" fontSize="large" /> : null}
      endIcon={project ? null : <ArrowRightIcon color="black" fontSize="large" />}
    >
      <MKTypography variant="body1" color="black" fontSize="16px" fontWeight="bold">
        {project?.display_name || 'Choose Project'}
      </MKTypography>
    </Button>
  );
};

export default ChooseProjectMenu;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import Container from '@mui/material/Container';
import BigBreadcrumb from 'components/BigBreadcrumb';
import { useProject } from 'contexts/project';
import { createSurvey, updateSurvey, getSurvey } from 'api/surveys';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SurveyForm from './surveyForm';

const SurveyCreate = () => {
  const { project } = useProject();
  const appId = project?.app_id;
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('surveyId');
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null);

  const fetchSurveyFromApi = useCallback((id) => {
    return getSurvey(id, {})
      .then(({ data }) => { setSurvey(data); });
  }, []);

  const updateSurveyToApi = useCallback((values) => {
    if (!surveyId) {
      return Promise.resolve();
    }
    const payload = {
      ...values,
    };
    updateSurvey(surveyId, payload)
      .then(() => { navigate('/reports/surveys'); });
  }, [navigate, surveyId]);

  const createSurveyToApi = useCallback((values) => {
    const payload = {
      ...values,
      app: appId,
    };
    createSurvey(payload)
      .then(() => { navigate('/reports/surveys'); });
  }, [appId, navigate]);

  useEffect(() => {
    if (surveyId) {
      fetchSurveyFromApi(surveyId);
    }
  }, [surveyId, fetchSurveyFromApi]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <BigBreadcrumb
          items={[
            { label: 'Reports', to: '/reports/overview' },
            { label: 'Surveys', to: '/reports/surveys' },
            { label: 'Create' },
          ]}
        />
        <MKBox mt={1}>
          <SurveyForm survey={survey} onSave={surveyId ? updateSurveyToApi : createSurveyToApi} />
        </MKBox>
      </Container>
    </MKBox>
  );
};

export default SurveyCreate;
